export const pluralize = (count: number, noun: string, suffix = 's') =>
  `${count.toLocaleString()} ${noun}${count !== 1 ? suffix : ''}`

/**
 * Converts free-form input to a valid url with https scheme or returns `undefined`.
 * @param input {string} free-form user input
 * @return {undefined | string} valid URL as string or nothing.
 */
export const convertStringToURL = (input: string | undefined): undefined | string => {
  if (!input) return
  try {
    let fixedURL = input.trim()
    fixedURL.replace('http://', '')
    if (!input.startsWith('https://')) fixedURL = `https://${fixedURL}`
    const parsedURL = new URL(fixedURL)
    if (!parsedURL.hostname.match(/\.\w{2,}$/)) return
    return parsedURL.toString()
  } catch {
    /* NOOP */
  }
}

import React, { ReactElement, useCallback } from 'react'

import { useIsDesktop } from '@hooks/useIsDesktop'

export const HoverMenu = (props: {
  children: ReactElement
  position: 'topRight' | 'topLeft' | 'bottomLeft'
  isVisible?: boolean
  toggleVisibility?: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { isVisible, toggleVisibility: toggleMobileVisibilty } = props
  const isMobile = !useIsDesktop()
  const handleOnClick = useCallback(() => {
    if (isMobile) {
      toggleMobileVisibilty?.(prev => !prev)
    }
  }, [toggleMobileVisibilty, isMobile])

  return (
    <div
      className={[
        `absolute z-50 w-auto duration-500 sm:group-hover:flex ${isVisible ? 'flex' : 'hidden'}`,
        props.position === 'topRight'
          ? 'right-1 top-full mt-1 animate-fade-in'
          : props.position === 'topLeft'
            ? 'left-1 top-full mt-1 animate-fade-in'
            : 'bottom-0 left-full ml-1 animate-fade-in',
      ].join(' ')}
      onClick={handleOnClick}
    >
      {props.children}
    </div>
  )
}

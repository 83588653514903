import { FC } from 'react'

import IconMediaArticle from '@icons/IconMediaArticle.svg'
import IconMediaGIF from '@icons/IconMediaGIF.svg'
import IconMediaImage from '@icons/IconMediaImage.svg'
import IconMediaVideo from '@icons/IconMediaVideo.svg'
import { FilterableMediaTypes } from '@models/MediaTypes'

export const MediaTypeIcons: MediaTypeToType<FC> = {
  IMAGE: IconMediaImage,
  VIDEO: IconMediaVideo,
  GIF: IconMediaGIF,
  ARTICLE: IconMediaArticle,
}
export const MediaTypeTitles: MediaTypeToType<string> = {
  IMAGE: 'Images',
  VIDEO: 'Videos',
  GIF: 'GIFs',
  ARTICLE: 'Articles',
}

type MediaTypeToType<Value> = {
  [K in (typeof FilterableMediaTypes)[number]]: Value
}

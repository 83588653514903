import React, { createContext, ReactNode, useCallback, useContext, useMemo } from 'react'
import { Updater } from 'use-immer'
import * as uuid from 'uuid'

import { useLocalStorageImmer } from '@hooks/useLocalStorageImmer'
import { isBrowser, isServer } from '@lib/runtime-environment'
import { MINUTE_TO_MS } from '@lib/time-constants'

interface SessionInfo {
  id: string
  maxAge: number
  started: boolean
}

interface SessionManagementContextProps {
  session: SessionInfo | null
  getCurrentSession: () => SessionInfo
  setSessionInfo: Updater<SessionInfo | null>
}

const SessionManagementContext = createContext<SessionManagementContextProps | undefined>(undefined)

const SESSION_INFO_KEY = 'sess_info'
const sessionMinutes = 30
const defaultSessionAge = sessionMinutes * MINUTE_TO_MS

const isSessionExpired = (sess: SessionInfo): boolean => sess.maxAge < Date.now()

const createSession = (): SessionInfo => ({
  id: uuid.v4(),
  maxAge: Date.now() + defaultSessionAge,
  started: false,
})

const storedSession = (): SessionInfo | null => {
  if (isServer() || typeof window.localStorage === 'undefined') return null
  const session = window.localStorage.getItem(SESSION_INFO_KEY)
  if (!session) return null
  try {
    return JSON.parse(session)
  } catch (_e) {
    window.localStorage.removeItem(SESSION_INFO_KEY)
    return null
  }
}

interface SessionManagementProviderProps {
  children: ReactNode
}

export const SessionManagementProvider: React.FC<SessionManagementProviderProps> = ({ children }) => {
  const [session, setSessionInfo] = useLocalStorageImmer<SessionInfo | null>(SESSION_INFO_KEY, storedSession())

  const getCurrentSession = useCallback(() => {
    const currentSession = storedSession()
    // Non-existent or expired session → create it
    if (!currentSession || isSessionExpired(currentSession)) {
      const newSession = createSession()
      // Store this right away to let next caller of `getCurrentSession` pick up.
      if (isBrowser()) {
        window.localStorage.setItem(SESSION_INFO_KEY, JSON.stringify(newSession))
      }
      // Communicate this to react, so we can track session event.
      setSessionInfo(newSession)
      return newSession
    }
    // An unexpired session → extend it in the React lifecycle.
    setSessionInfo(previousSession => {
      if (!previousSession) return null
      previousSession.maxAge = Date.now() + defaultSessionAge
    })
    return currentSession
  }, [setSessionInfo])

  const value = useMemo(() => {
    return { session, getCurrentSession, setSessionInfo }
  }, [session, getCurrentSession, setSessionInfo])

  return <SessionManagementContext.Provider value={value}>{children}</SessionManagementContext.Provider>
}

export const useSession = (): SessionManagementContextProps => {
  const context = useContext(SessionManagementContext)
  if (!context) {
    throw new Error('useSession must be used within a SessionManagementProvider')
  }
  return context
}

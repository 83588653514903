import { IncomingHttpHeaders, IncomingMessage } from 'node:http'
import { InternalAxiosRequestConfig } from 'axios'
import { getCookie } from 'cookies-next'

export const canaryHeaderName = 'into-prefer-canary'

export const isCanary = (req?: { headers: IncomingHttpHeaders }) => {
  const tweaks = getCookie('persist:tweaks', { req: req as IncomingMessage })
  return (
    (!!tweaks && JSON.parse(tweaks).isCanary === 'true') || // set in the cookies by the web-app.
    req?.headers[canaryHeaderName] === 'true' // set in the header from the request from a native client.
  )
}

export const setCanaryInterceptor = (config: InternalAxiosRequestConfig<unknown>) => {
  if (config.baseURL?.startsWith('https://into.mix.com/') && isCanary(config)) {
    config.baseURL = config.baseURL.replace('into', 'canary-into')
  }
  return config
}

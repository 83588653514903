import { ThemeProvider as NextThemeProvider, useTheme as useNextTheme } from 'next-themes'
import { createContext, ReactNode, useContext, useState } from 'react'

interface ThemeContextType {
  forcedTheme: string | undefined
  setForcedTheme: (theme: string | undefined) => void
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined)

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [forcedTheme, setForcedTheme] = useState<string | undefined>()

  return (
    <ThemeContext.Provider value={{ forcedTheme, setForcedTheme }}>
      <NextThemeProvider attribute="data-theme" enableSystem defaultTheme="system" forcedTheme={forcedTheme}>
        {children}
      </NextThemeProvider>
    </ThemeContext.Provider>
  )
}

export const useTheme = () => {
  const context = useContext(ThemeContext)
  const nextTheme = useNextTheme()

  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }

  return {
    ...nextTheme,
    forcedTheme: context.forcedTheme,
    setForcedTheme: context.setForcedTheme,
    resolvedTheme: context.forcedTheme || nextTheme.resolvedTheme,
  }
}

import debounce from 'lodash.debounce'
import { ApiError } from 'next/dist/server/api-utils'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import Modal, { ChildModalProps } from '@common/Modal'
import { USER_ENTRY_DEBOUNCE, useUrlSubmission } from '@desktop/AddUrlModal'
import { UrlExtractMeta, UrlExtractMetaPlaceholder } from '@desktop/UrlExtractMeta'
import { useRequest } from '@hooks/useRequest'
import IconPlus from '@icons/IconPlus.svg'
import { convertStringToURL } from '@lib/StringHelper'
import { errorToHelpfulMessage } from '@models/APIErrorResponse'
import { ExtractMetaResponse } from '@models/ExtractResponse'
import MixMix from '@services/MixMix'

const FOCUS_TIMEOUT = 100

export const ContestSubmitModal = ({ isVisible, onClose }: ChildModalProps) => {
  const [url, setUrl] = useState<string | undefined>(undefined)
  const [error, setError] = useState<Error | undefined>(undefined)
  const handleClose = useCallback(() => {
    setUrl(undefined)
    onClose(false)
  }, [onClose])

  const { submitRateAndSeeUrl } = useUrlSubmission('contest_breakdancing')

  const onFormSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>, url: string) => {
      event.preventDefault()
      await submitRateAndSeeUrl(url)
        .then(handleClose)
        .catch(err => {
          setError(err)
        })
    },
    [handleClose, submitRateAndSeeUrl]
  )

  const changeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setError(undefined)
      setUrl(convertStringToURL(event.target.value))
      event.preventDefault()
    },
    [setUrl, setError]
  )
  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, USER_ENTRY_DEBOUNCE), [changeHandler])

  const {
    data: urlMeta,
    isLoading: isLoadingUrlMeta,
    error: urlMetaError,
  } = useRequest<ExtractMetaResponse, ApiError>(url ? MixMix.extract.getMeta({ url }) : null)

  useEffect(() => {
    if (!urlMeta) {
      setError(undefined)
      return
    }
    if (urlMeta.url.includes('youtube.com') || urlMeta.url.includes('youtu.be')) {
      setError(undefined)
      return
    }
    setError(new Error('Please enter a valid YouTube link'))
  }, [urlMeta])
  useEffect(() => {
    if (urlMetaError) {
      setError(urlMetaError)
    }
  }, [urlMetaError])

  const inputRef = useRef<HTMLInputElement | null>(null)
  useEffect(() => {
    setTimeout(() => {
      if (isVisible) inputRef.current?.focus()
    }, FOCUS_TIMEOUT)
  }, [isVisible])

  return (
    <Modal isVisible={isVisible} onClose={handleClose} returnUrl="/contest/breakdancing">
      <div className="relative z-10 space-y-8 rounded bg-white p-6 text-black sm:w-[30rem]">
        <button className="absolute right-4 top-4 text-[#999999]" onClick={handleClose}>
          <IconPlus className="size-5 rotate-45" />
        </button>
        <div className="text-3xl font-bold">Submit to the contest</div>
        <form
          className="space-y-8"
          onSubmit={event => (urlMeta?.url ? onFormSubmit(event, urlMeta.url).catch(() => {}) : handleClose)}
        >
          <div className="flex h-[100px] items-center space-x-8 overflow-hidden rounded-lg border border-black/10">
            <Image src="/contest-breakdancing.png" alt="Contest Image" height={100} width={100} />
            <div>
              <div className="flex w-fit space-x-1 rounded-lg bg-accent-dark px-2 py-1.5 text-[13px] font-medium text-accent sm:px-3">
                <div>⭐</div>
                <div>Curation Contest</div>
              </div>
              <div className="text-2xl font-bold sm:text-3xl">Breakdancing</div>
            </div>
          </div>
          <input
            type="url"
            className="w-full rounded-lg border border-black/10 p-4"
            onChange={debouncedChangeHandler}
            placeholder="Type or paste a Youtube link"
            ref={inputRef}
          />
          {((urlMeta && !error) || isLoadingUrlMeta) && (
            <div className="flex h-[100px] items-center space-x-8 overflow-hidden rounded-lg border border-black/10">
              {urlMeta && <UrlExtractMeta {...urlMeta} />}
              {isLoadingUrlMeta && <UrlExtractMetaPlaceholder isLoading={isLoadingUrlMeta} />}
            </div>
          )}
          {error && (
            <div className="flex items-center justify-center rounded-lg border border-red-200 bg-red-50 p-4 text-lg text-red-500">
              {errorToHelpfulMessage(error)}
            </div>
          )}
          <div className="text-black/70">
            By continuing, you agree to Mix’s Terms of services and community guideline
          </div>
          {urlMeta && !error && (
            <input
              disabled={!!error}
              type="submit"
              className="btn cursor-pointer bg-accent py-2 text-[14px] font-semibold text-white disabled:bg-black/10 disabled:hover:scale-100"
              value="Submit"
            />
          )}
        </form>
      </div>
    </Modal>
  )
}

export const useContestSubmitModal = () => {
  const router = useRouter()
  const [isModalVisible, setIsModalVisible] = useState(router.asPath === '/contest/submit')

  return {
    isModalVisible,
    onModalShow: () => setIsModalVisible(true),
    onModalClose: () => setIsModalVisible(false),
  }
}

import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { ForwardedRef, forwardRef, useEffect, useState } from 'react'

import Modal from '@common/Modal'
import { useTrackClick } from '@hooks/analytics/useTrackClick'
import IconApple from '@icons/IconApple.svg'
import IconFacebook from '@icons/IconAuthFacebook.svg'
import IconTwitter from '@icons/IconAuthTwitter.svg'
import IconEmail from '@icons/IconEmail.svg'
import IconGoogle from '@icons/IconGoogle.svg'
import IconPlus from '@icons/IconPlus.svg'
import IconSpinner from '@icons/IconSpinner.svg'
import { setIsLoginModalOpen, setLoginModalInnerUI } from '@redux/slices/modals/loginModalSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'
import { AuthLink, EmailAuthInput } from './AuthLink'

interface LoginModalCopy {
  title: string
  subtitle: string
}

const CLEAR_ERROR_TIME = 5000

// Variations of copy on the login modal based on the way we show them
const loginCopy: Record<'default' | 'add' | 'login' | 'captcha' | 'personalize' | 'conversation', LoginModalCopy> = {
  // This is the default copy.
  default: {
    title: 'Join Mix',
    subtitle: 'Your AI powered personal feed of images, videos, gifs and more.',
  },
  // Shown when an unauthenticated user visits the /add page
  add: {
    title: 'Welcome to Mix',
    subtitle: 'Please sign up or login to submit the link.',
  },
  // Shown when a user clicks on a "Login" button somewhere (not the Join or Sign up).
  login: {
    title: 'Welcome to Mix',
    subtitle: 'Please login to continue.',
  },
  // Shown when we present captcha box during the sign-up.
  captcha: {
    title: 'Before we continue',
    subtitle: 'Please verify you are a human',
  },
  // Shown when we automatically trigger the login modal on scroll
  personalize: {
    title: 'Join Mix to personalize your experience',
    subtitle: 'Your Al-powered personal feed of interesting things for you.',
  },
  // Shown when user is not logged in and clicks on the "Join the conversation" button
  conversation: {
    title: 'Join the conversation',
    subtitle: 'Sign in to join the conversation',
  },
}

const LoginModalErrorMessage = ({
  errorMessage,
  setErrorMessage,
}: {
  errorMessage: string | undefined
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>
}) => {
  const dispatch = useAppDispatch()
  const router = useRouter()

  useEffect(() => {
    if (router.query.provider === 'email' && router.query.error === 'expired') {
      setErrorMessage('That link has expired. Please enter your email address to receive a new link.')
      dispatch(setLoginModalInnerUI('email'))
    }
    if (router.query.error === 'verificationError' || router.query.error === 'missingToken') {
      setErrorMessage(`We couldn't verify your request. Please try again or use a different login method.`)
      setTimeout(() => {
        setErrorMessage(undefined)
      }, CLEAR_ERROR_TIME)
    }
  }, [dispatch, setErrorMessage, router.query])

  if (!errorMessage) return null
  return <div className="text-lg text-red-500">{errorMessage}</div>
}

const LoginModalSubtitle = () => {
  const { loginModalInnerUI, copyVariant } = useAppSelector(state => state.loginModal)
  const subtitle = loginModalInnerUI.startsWith('email')
    ? loginModalInnerUI === 'emailSubmitted'
      ? 'We just emailed you a login link.'
      : 'Please enter your email address.'
    : loginCopy[copyVariant].subtitle

  return (
    <div className="space-y-2">
      <div className="text-xl">{subtitle}</div>
      {loginModalInnerUI === 'email' && <div> You will receive a link to log in to Mix.</div>}
      {loginModalInnerUI === 'emailSubmitted' && (
        <div> If you don’t see the email within a few minutes, check your spam folder.</div>
      )}
    </div>
  )
}

const LoginModalInputUI = ({
  setErrorMessage,
}: {
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>
}) => {
  const dispatch = useAppDispatch()
  const { loginModalInnerUI, returnUrl } = useAppSelector(state => state.loginModal)
  const trackClick = useTrackClick()

  if (loginModalInnerUI === 'emailSubmitted') return null
  if (loginModalInnerUI === 'email') return <EmailAuthInput setErrorMessage={setErrorMessage} />
  return (
    <>
      <AuthLink
        name="Facebook"
        buttonClass="text-sm hover:border-accent border-2 rounded-lg"
        {...{ returnUrl }}
        onClick={() => setErrorMessage(undefined)}
      >
        {isSubmitting => (
          <>
            <span className="w-6">
              <IconFacebook />
            </span>
            <span className="w-full text-left">Facebook</span>
            {isSubmitting && <IconSpinner className="h-6" />}
          </>
        )}
      </AuthLink>
      <AuthLink
        name="Twitter"
        buttonClass="text-sm hover:border-accent border-2 rounded-lg"
        onClick={() => setErrorMessage(undefined)}
        {...{ returnUrl }}
      >
        {isSubmitting => (
          <>
            <span className="w-6">
              <IconTwitter />
            </span>
            <span className="w-full text-left">Twitter</span>
            {isSubmitting && <IconSpinner className="h-6" />}
          </>
        )}
      </AuthLink>
      <AuthLink
        name="Google"
        buttonClass="text-sm hover:border-accent border-2 rounded-lg"
        onClick={() => setErrorMessage(undefined)}
        {...{ returnUrl }}
      >
        {isSubmitting => (
          <>
            <span className="w-6">
              <IconGoogle />
            </span>
            <span className="w-full text-left">Google</span>
            {isSubmitting && <IconSpinner className="h-6" />}
          </>
        )}
      </AuthLink>
      <AuthLink
        name="Apple"
        buttonClass="text-sm hover:border-accent border-2 rounded-lg"
        onClick={() => setErrorMessage(undefined)}
        {...{ returnUrl }}
      >
        {isSubmitting => (
          <>
            <span className="w-6">
              <IconApple />
            </span>
            <span className="w-full text-left">Apple</span>
            {isSubmitting && <IconSpinner className="h-6" />}
          </>
        )}
      </AuthLink>
      <button
        onClick={() => {
          trackClick('emailAuth')
          dispatch(setLoginModalInnerUI('email'))
        }}
        className="btn btn-square flex w-full cursor-pointer items-center space-x-4 rounded-lg border-2 p-4 text-sm hover:border-accent focus:outline-none"
      >
        <span className="w-6">
          <IconEmail />
        </span>
        <span className="w-full text-left">Email</span>
      </button>
    </>
  )
}

const LoginModalInner = (_: unknown, ref: ForwardedRef<HTMLElement>) => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const trackClick = useTrackClick()
  const { isLoginModalOpen, returnPathOnClose, copyVariant } = useAppSelector(state => state.loginModal)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  const onClose = () => {
    dispatch(setIsLoginModalOpen(false))
    dispatch(setLoginModalInnerUI('default'))
    if (returnPathOnClose) router.replace('/', returnPathOnClose, { shallow: true }).catch(() => {})
  }
  const copy = loginCopy[copyVariant]

  return (
    <Modal isVisible={isLoginModalOpen} onClose={onClose} ref={ref}>
      <div className="relative w-full rounded-lg bg-menu p-10 pt-12 text-menu selection:bg-accent/50 sm:w-[30rem]">
        <button
          className="absolute right-6 top-6 rounded-full p-2 transition-colors hover:bg-gray-100"
          tabIndex={-1}
          onClick={onClose}
          aria-label="Close modal"
        >
          <IconPlus className="size-4 rotate-45 text-gray-500" />
        </button>
        <div className="mb-6 text-3xl font-bold">{copy.title}</div>
        <div className="space-y-8">
          {!errorMessage && <LoginModalSubtitle />}
          <LoginModalErrorMessage errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
          <div className="w-full flex-col space-y-2">
            <LoginModalInputUI setErrorMessage={setErrorMessage} />
          </div>
          <div className="text-sm leading-tight text-black/60">
            By continuing, you are setting up a Mix account and agree to our{' '}
            <Link href="/tos" onClick={() => trackClick('termsOfServices')} className="text-accent">
              Terms of Services
            </Link>{' '}
            and{' '}
            <Link href="/privacy" onClick={() => trackClick('privacyPolicy')} className="text-accent">
              Privacy Policy.
            </Link>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export const LoginModal = forwardRef(LoginModalInner)

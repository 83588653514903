import React, { createContext, ReactNode, useContext, useState } from 'react'

interface ProgressBarContextProps {
  progressBar: ReactNode | null
  setProgressBar: (progressBar: ReactNode) => void
  clearProgressBar: () => void
}

const ProgressBarContext = createContext<ProgressBarContextProps | undefined>(undefined)

export const ProgressBarProvider = ({ children }: { children: ReactNode }) => {
  const [progressBar, setProgressBarState] = useState<ReactNode | null>(null)

  const setProgressBar = (progressBarContent: ReactNode) => {
    setProgressBarState(progressBarContent)
  }

  const clearProgressBar = () => {
    setProgressBarState(null)
  }

  return (
    <ProgressBarContext.Provider value={{ progressBar, setProgressBar, clearProgressBar }}>
      {children}
    </ProgressBarContext.Provider>
  )
}

export const useProgressBar = () => {
  const context = useContext(ProgressBarContext)
  if (context === undefined) {
    throw new Error('useProgressBar must be used within a ProgressBarProvider')
  }
  return context
}

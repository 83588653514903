import Image from 'next/image'
import Link from 'next/link'
import React, { ReactElement } from 'react'

import IconBell from '@icons/IconBell.svg'

const CONTEST_CATEGORY = 'Breakdancing'
const CONTEST_SLUG = 'contest-breakdancing-2024'
const DEADLINE = 'October 31st'

const InfoPanel = ({
  icon,
  text,
  className,
  children,
}: {
  icon: ReactElement
  text: string
  className?: string
  children: ReactElement
}) => {
  return (
    <div className={`flex flex-col items-center space-y-6 p-12 font-medium sm:p-24 sm:pt-16 ${className}`}>
      <div className="flex size-8 items-center justify-center rounded-md bg-accent/20 text-xs sm:size-10 sm:text-lg">
        {icon}
      </div>
      <div className="text-center text-xl sm:text-3xl">{text}</div>
      <div className="pt-6">{children}</div>
    </div>
  )
}

const StepPanel = ({
  headline,
  className,
  imageSrc,
  children,
  blurElement,
}: {
  headline: string
  className: string
  imageSrc: string
  children: ReactElement
  blurElement?: ReactElement
}) => (
  <div className={`${className} relative z-20 bg-top bg-no-repeat`}>
    <div className="relative">
      <div className="mb-2 text-lg font-bold sm:text-2xl">{headline}</div>
      <div className="space-y-3 sm:text-xl">{children}</div>
      <Image className="w-full" src={imageSrc} alt="Step" width={100} height={100} />
      {blurElement}
    </div>
  </div>
)

const StepPanels = () => (
  <div className="space-y-16 rounded-3xl bg-[#181818] text-center sm:space-y-28">
    <div className="flex flex-col pt-20 text-2xl font-bold">How it works</div>
    <StepPanel
      headline="Topic Selected"
      imageSrc="/contest-image-1.png"
      className="bg-[url('/step-1.png')] bg-[length:18%] pt-[18%]"
    >
      <div className="text-white/80">Each contest season we’ll choose a new topic.</div>
    </StepPanel>
    <StepPanel
      headline="Curate Gems"
      imageSrc="/contest-image-2.png"
      className="bg-[url('/step-2.png')] bg-[length:25%] pt-[18%]"
      blurElement={
        <div className="absolute top-0 -z-10 flex aspect-square w-full items-center justify-center blur-[252px]">
          <div className="size-full rounded-full bg-black" />
        </div>
      }
    >
      <>
        <div className="text-white/80">
          Find and submit awesome YouTube
          <br /> videos related to the contest topic.
        </div>
        <div className="text-white/50">
          Videos about other topics are not
          <br /> eligible to win.
        </div>
      </>
    </StepPanel>
    <StepPanel
      headline="Win Prizes"
      imageSrc="/contest-image-3.png"
      className="!mt-0 bg-[url('/step-3.png')] bg-[length:25%] pt-[15%]"
    >
      <div className="text-white/80">
        We’ll select five of our favorite submissions.
        <br /> Each winner receives a <span className="text-accent">$500 gift card</span>.
      </div>
    </StepPanel>
  </div>
)

const ContestFooter = () => (
  <div className="space-y-6 p-16 pb-48 text-center text-sm sm:pb-60 sm:text-xl">
    <div className="text-[#6D6D6D]/80">
      Contest ends October 31st. US users only. Videos submitted must be from YouTube. Videos not related to the contest
      topic will be automatically rejected.
    </div>
    <div>
      See full{' '}
      <Link href="/about/contest-rules" className="text-accent">
        terms and conditions.
      </Link>
    </div>
  </div>
)

export const ContestScrollingPanel = () => {
  return (
    <div className="relative z-20 -mt-32 flex flex-col items-center backdrop-blur sm:-mt-48">
      <div className="max-w-3xl rounded-t-3xl bg-black/50">
        <InfoPanel
          icon={<span>⭐</span>}
          text={`To enter, submit ${CONTEST_CATEGORY.toLowerCase()} videos by ${DEADLINE}.`}
        >
          <Link href={`/topic/${CONTEST_SLUG}`} className="pt-6 text-lg text-accent underline sm:text-2xl">
            Browse contest videos
          </Link>
        </InfoPanel>
        <div className="rounded-t-3xl bg-black">
          <StepPanels />
          <InfoPanel
            icon={<IconBell className="size-full p-2 text-accent" />}
            text="Visit this page anytime from Notifications."
            className="pb-0 sm:pb-0"
          >
            <Image className="w-full" src="/contest-image-notifications.png" alt="Prize" width={100} height={100} />
          </InfoPanel>
          <ContestFooter />
        </div>
      </div>
    </div>
  )
}

import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import { useTheme } from '@context/ThemeContext'
import { ContextMenu } from '@desktop/ContextMenu'
import { useIsDesktop } from '@hooks/useIsDesktop'
import { useIsSmallScreen } from '@hooks/useIsSmallScreen'
import IconMenuHamburger from '@icons/IconMenuHamburger.svg'
import { useAppSelector } from '@redux/store/store'
import LoginButton from '../login/LoginButton'
import { ToolbarTitle } from '../toolbar/ToolbarFeedTitle'
import { ToolbarActions } from '../toolbar/ToolbarUrlActions'
import Header from './Header'

const LoggedOutHeaderLogo = () => (
  <div className="flex translate-y-2">
    <Link href="/" className="btn btn-link">
      <div className="relative h-8 w-24">
        <Image
          src={useTheme().resolvedTheme === 'dark' ? '/logo_white.svg' : '/logo_black.svg'}
          alt="Mix"
          priority={true}
          fill={true}
        />
      </div>
    </Link>
  </div>
)

const LoggedOutHeaderCTA = () => {
  const isDesktop = useIsDesktop()

  const LoginButtonText = useMemo(() => {
    if (!isDesktop) return 'Get the app'
    return 'Join Mix'
  }, [isDesktop])

  return <LoginButton className="btn btn-primary py-2 text-sm font-semibold">{LoginButtonText}</LoginButton>
}

const LoggedOutHeaderMenu = () => {
  const [showMobileContextMenu, setShowMobileContextMenu] = useState(false)

  const menuRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: TouchEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowMobileContextMenu(false)
      }
    }
    document.addEventListener('touchstart', handleClickOutside)

    return () => {
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [])

  return (
    <div ref={menuRef} className="relative inline-block">
      <div
        slot="trigger"
        className={`group cursor-pointer rounded-full p-2 sm:hover:bg-primary/90 sm:hover:before:fixed sm:hover:before:right-0 sm:hover:before:top-0 sm:hover:before:z-10 sm:hover:before:block sm:hover:before:h-48 sm:hover:before:w-20 ${showMobileContextMenu ? 'bg-primary/90' : ''} `}
      >
        <ContextMenu
          position="topRight"
          isVisible={showMobileContextMenu}
          toggleVisibility={setShowMobileContextMenu}
        />
        <div
          className="relative"
          onTouchStart={e => {
            e.stopPropagation()
            setShowMobileContextMenu(prev => !prev)
          }}
        >
          <div className="absolute inset-0 m-[-15px] opacity-0"></div>
          <div
            className={`aspect-square w-6 text-primary transition-opacity duration-200 sm:hover:opacity-100 group-hover:sm:opacity-100 ${showMobileContextMenu ? 'opacity-100' : 'opacity-50'} `}
          >
            <IconMenuHamburger />
          </div>
        </div>
      </div>
    </div>
  )
}

const LoggedOutHeader = () => {
  const isSmallScreen = useIsSmallScreen()
  const { isTitleEnabled } = useAppSelector(state => state.header)
  const { identifier } = useAppSelector(state => state.feed)
  const router = useRouter()

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.async = true
    script.defer = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [router.route])

  return (
    <Header className="px-4 py-3">
      <div className="flex w-full items-center space-x-2">
        <LoggedOutHeaderLogo />
        {isTitleEnabled ? (
          <ToolbarTitle isDropdownEnabled={false} isSmallScreen={isSmallScreen} identifier={identifier} />
        ) : (
          <></>
        )}
      </div>
      <ToolbarActions />
      <div className="flex w-full items-center justify-end space-x-4">
        <LoggedOutHeaderCTA />
        <LoggedOutHeaderMenu />
      </div>
    </Header>
  )
}

export default LoggedOutHeader

import BestOf from './variations/best-of'
import CuriousMinds from './variations/curious-minds'
import DailyDose from './variations/daily-dose'
import DiscoveryEngine from './variations/discovery-engine'
import Expand from './variations/expand'
import ExpandGems from './variations/expand_gems'
import Gems from './variations/gems'
import RabbitHole from './variations/rabbit-hole'
import SurprisinglyPersonal from './variations/surprisingly-personal'
import Swipe from './variations/swipe'

const Taglines = {
  '/best-of': BestOf,
  '/curious-minds': CuriousMinds,
  '/daily-dose': DailyDose,
  '/discovery-engine': DiscoveryEngine,
  default: ExpandGems,
  '/': ExpandGems,
  '/expand': Expand,
  '/expand_gems': ExpandGems,
  '/gems': Gems,
  '/rabbit-hole': RabbitHole,
  '/surprisingly-personal': SurprisinglyPersonal,
  '/swipe': Swipe,
}

export default Taglines

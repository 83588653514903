import { useCallback } from 'react'

import { useBottomSheet } from '@context/BottomSheetContext'
import IconCaretDown from '@icons/IconCaretDown.svg'
import nodeApi from '@redux/api/nodeApi'
import userApi from '@redux/api/userApi'
import { selectUserId } from '@redux/slices/userSlice'
import { useAppSelector } from '@redux/store/store'
import MobileFeedFilters from './FeedFilters'

const FilterButton = () => {
  const identifier = useAppSelector(state => state.feed.identifier)

  const { openBottomSheet } = useBottomSheet()

  const handleClick = useCallback(() => {
    openBottomSheet(<MobileFeedFilters />)
  }, [openBottomSheet])

  // Fetch user or node details based on the identifier
  const userId = useAppSelector(selectUserId)
  const userQueryResult = userApi.useGetUserDetailsQuery(
    { userId: userId ?? 0 },
    { skip: !userId || identifier.slug !== 'user' }
  )
  const user = userQueryResult.data

  const nodeQueryResult = nodeApi.useGetNodeDetailsQuery(
    { slug: identifier.slug },
    { skip: identifier.slug === 'user' }
  )
  const node = nodeQueryResult.data

  let displayName = 'For You' // default

  if (identifier.slug === 'user' && user) {
    displayName = `@${user.username}`
  } else if (node && node.name) {
    displayName = node.name
  } else if (identifier.name) {
    displayName = identifier.name
  }

  return (
    <div onClick={handleClick} className="flex items-center space-x-1 rounded-full px-4 py-2 pr-2">
      <span className="block pb-px text-lg font-semibold">{displayName}</span>
      <div className="block px-1">
        <IconCaretDown className="size-4" />
      </div>
    </div>
  )
}

export default FilterButton

import * as uuid from 'uuid'

import { isServer } from '../runtime-environment'

export const resolveDeviceUUID = () => {
  if (isServer()) return undefined
  if (typeof window.localStorage === 'undefined') return undefined
  const deviceUUID = window.localStorage.getItem('d_id')
  if (!deviceUUID) {
    const newID = uuid.v4()
    window.localStorage.setItem('d_id', newID)
    return newID
  }
  return deviceUUID
}

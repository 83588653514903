import '@styles/globals.css'
import '@services/MixAxios'

import { getCookie } from 'cookies-next'
import { enableMapSet } from 'immer'
import { PagesProgressBar as ProgressBar } from 'next-nprogress-bar'
import type { AppContext, AppInitialProps, AppProps } from 'next/app'
import App from 'next/app'
import Head from 'next/head'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Toaster } from 'react-hot-toast'
import { persistStore } from 'redux-persist'

import BusinessEventsModal from '@components/BusinessEventsModal'
import { CommandContextInterceptor } from '@context/CommandContextInterceptor'
import { ObserveSessionID } from '@context/ObserveSessionID'
import { ReferrerContextObserver } from '@context/ReferrerContextObserver'
import { AddUrlModal } from '@desktop/AddUrlModal'
import { AppProviders } from '@desktop/AppProviders'
import DesktopLayout from '@desktop/layouts/Layout'
import { LoginModal } from '@desktop/login/LoginModal'
import { MetaTags } from '@desktop/MetaTags'
import { RootSWRegistry } from '@desktop/RootSWRegistry'
import { RouterEvents } from '@desktop/Router'
import { shouldUseMobileLayout } from '@hooks/useMobileLayout'
import useRootServiceWorker from '@hooks/useServiceWorker'
import { BranchSDK } from '@lib/analytics/BranchSDK'
import { GoogleAnalytics } from '@lib/analytics/GoogleAnalytics'
import { BrowserInfo, getBrowserInfo } from '@lib/browser'
import runtimeConfig from '@lib/RuntimeConfig'
import MobileLayout from '@mobile/layouts/Layout'
import { IntoNode } from '@models/IntoNode'
import { IntoUrl } from '@models/IntoUrl'
import { IntoUser } from '@models/IntoUser'
import { setReqIP } from '@redux/slices/appSlice'
import { AppDispatch, AppStore, wrapper } from '@redux/store/store'
import utils from '@server/lib/utils'
import { MessageServiceProvider } from '@tracking/context/MessageServiceContext'
import { CurationContestInterstitialModal } from './internal/interstitial/curation-contest.page'

// App level initialization
// Enable map & set support for immer.js https://immerjs.github.io/immer/map-set/
enableMapSet()
type InitialProps = AppInitialProps & { isLoggedIn: boolean; browserInfo: BrowserInfo }

type MixAppProps = AppProps<{ pageName?: string; nodeDetails?: IntoNode; user?: IntoUser; url?: IntoUrl } | undefined> &
  InitialProps

const MixApp = ({ Component, ...rest }: MixAppProps) => {
  useRootServiceWorker()
  const { store, props }: { store: AppStore; props: InitialProps & MixAppProps } = wrapper.useWrappedStore(rest)
  const { pageProps, isLoggedIn, browserInfo, router } = props
  persistStore(store)
  const { isDesktop } = browserInfo
  const shouldRenderMobile = shouldUseMobileLayout(isDesktop, isLoggedIn, router.pathname)
  const LayoutComponent = shouldRenderMobile ? MobileLayout : DesktopLayout

  return (
    <AppProviders store={store} browserInfo={browserInfo} isLoggedIn={isLoggedIn} pageName={pageProps?.pageName}>
      {!isDesktop && (
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        </Head>
      )}
      <CommandContextInterceptor />
      <ObserveSessionID />
      <RouterEvents />
      <BranchSDK />
      <GoogleAnalytics />
      <ReferrerContextObserver />
      {isDesktop && <ProgressBar color="#FF8226" height="2px" options={{ showSpinner: false, trickle: false }} />}
      {/* MetaTags must be rendered before LayoutComponent to avoid overwriting the tags set by the child component */}
      <MetaTags />
      <LayoutComponent>
        <>
          <GoogleReCaptchaProvider
            useEnterprise={true}
            reCaptchaKey={runtimeConfig().publicRuntimeConfig.auth.recaptchaEnterpriseKey}
          >
            <MessageServiceProvider>
              <RootSWRegistry />
              <Component {...pageProps} />
              {/* Modal Root*/}
              <LoginModal />
              <AddUrlModal />
              <CurationContestInterstitialModal />
              <BusinessEventsModal />
            </MessageServiceProvider>
          </GoogleReCaptchaProvider>
        </>
      </LayoutComponent>
      <Toaster />
    </AppProviders>
  )
}

MixApp.getInitialProps = wrapper.getInitialAppProps(store => async (appContext: AppContext): Promise<InitialProps> => {
  const { ctx } = appContext
  const { req } = ctx

  const dispatch = store.dispatch as AppDispatch
  if (req) {
    const reqIP = utils.ipFromReq(req)
    if (reqIP) dispatch(setReqIP(reqIP))
  }
  const userAgent = typeof window === 'undefined' ? req?.headers['user-agent'] : window.navigator.userAgent
  const browserInfo = getBrowserInfo(userAgent ?? '')
  if (getCookie('is_ipad', { req }) === 'true') {
    browserInfo.isDesktop = false
    browserInfo.isTablet = true
  }
  const isLoggedIn =
    getCookie(runtimeConfig().publicRuntimeConfig.mixIdentifiedCookieName, {
      req,
    }) === 'true'
  const { pageProps } = await App.getInitialProps(appContext)
  return { ...pageProps, browserInfo, isLoggedIn }
})

export default MixApp

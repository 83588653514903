import Head from 'next/head'
import { useRouter } from 'next/router'
import { ReactElement, useEffect } from 'react'

import UserProfileLayout from '@common/layouts/UserProfileLayout'
import { useCurrentUserContext } from '@context/CurrentUserContext'
import { useTheme } from '@context/ThemeContext'
import { DownloadAppPeekabooPanel } from '@desktop/download/DownloadAppPeekabooPanel'
import Header from '@mobile/Header'
import TabNav from '@mobile/TabNav'
import { selectChromeState } from '@redux/slices/mobileSlice'
import { useAppSelector } from '@redux/store/store'

const MobileLayout = ({ children }: { children: ReactElement }) => {
  const { isLoggedIn } = useCurrentUserContext()
  const { setForcedTheme } = useTheme()
  const { hideHeader, hideTabNav } = useAppSelector(selectChromeState)
  const pathname = useRouter().pathname

  useEffect(() => setForcedTheme('dark'), [setForcedTheme])

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
      </Head>
      <div className="screen">
        <div className="size-full col">
          {!hideHeader && <Header />}
          <div className="flex overflow-auto scrollbar-hide">
            {pathname.startsWith('/!') ? children : <UserProfileLayout className="pt-6">{children}</UserProfileLayout>}
          </div>
          {!hideTabNav && isLoggedIn && <TabNav />}
        </div>
      </div>
      <DownloadAppPeekabooPanel />
    </>
  )
}

export default MobileLayout

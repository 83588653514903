const DiscoveryEngineCopy = {
  heading: 'Your personal discovery engine',
  subheading: 'Mix learns what you like, and finds just the right thing for you.',
}

const DiscoveryEngineElement = () => (
  <>
    <h1 className="mb-8 text-5xl font-bold tracking-tight md:text-7xl lg:text-8xl 2xl:mb-12 2xl:text-[120px]">
      Your personal
      <br />
      discovery engine
    </h1>
    <h2 className="mb-10 text-lg leading-tight opacity-50 lg:text-xl 2xl:text-2xl">
      Mix learns what you like,
      <br />
      and finds just the right thing for you.
    </h2>
  </>
)

const DiscoveryEngine = { element: DiscoveryEngineElement, copy: DiscoveryEngineCopy }

export default DiscoveryEngine

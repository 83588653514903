const CuriousMindsCopy = {
  heading: 'The app for curious minds',
  subheading: 'Mix learns what you like, and finds new related things for you.',
}

const CuriousMindsElement = () => (
  <>
    <h1 className="mb-8 text-6xl font-bold tracking-tight md:text-7xl lg:text-8xl 2xl:mb-12 2xl:text-[120px]">
      The app for
      <br />
      curious minds
    </h1>
    <h2 className="mb-10 text-lg leading-tight opacity-50 lg:text-xl 2xl:text-2xl">
      Mix learns what you like,
      <br />
      and finds new related things for you.
    </h2>
  </>
)

const CuriousMinds = { element: CuriousMindsElement, copy: CuriousMindsCopy }

export default CuriousMinds

import { AnimatePresence, motion, PanInfo } from 'framer-motion'
import { useRouter } from 'next/router'
import { ReactNode, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

export const SIDE_PANEL_Z_INDEX = 'z-[52]'
const CLOSE_THRESHOLD = 50

const SidePanel = ({
  children,
  isOpen,
  closePanel,
}: {
  children: ReactNode
  isOpen: boolean
  closePanel: () => void
}) => {
  const [mounted, setMounted] = useState(false)
  const router = useRouter()

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    const handleRouteChange = () => {
      if (isOpen) {
        closePanel()
      }
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, isOpen, closePanel])

  const handleDragEnd = (_event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    if (info.offset.x > CLOSE_THRESHOLD) closePanel()
  }

  if (!mounted) return null

  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <div className={`fixed inset-0 ${SIDE_PANEL_Z_INDEX}`}>
          <motion.div
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ type: 'tween', duration: 0.3 }}
            drag="x"
            dragConstraints={{ left: 0 }}
            dragElastic={0}
            onDragEnd={handleDragEnd}
            onClick={e => e.stopPropagation()}
            style={{ touchAction: 'pan-y' }}
            className="fixed right-0 top-0 size-full overflow-y-auto bg-primary"
          >
            <div className="py-4">{children}</div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>,
    document.body
  )
}

export default SidePanel

import { APIResource, APIResourceType, FeedItem, keyForResource } from './APIResource'
import { IntoUrl } from './IntoUrl'
import { MediaType } from './MediaTypes'

export default interface RecommendResponse {
  items: FeedItem[]
  meta?: {
    mediaTypes?: MediaType[] | null
    total?: number
    insertAfter?: number
  }
}

export const filterIdsForRequest = (urls: IntoUrl[]) => {
  return urls.map(({ url_id: urlId }) => urlId)
}

export const filterIdsForResponse = (recommendResponse: RecommendResponse | undefined) => {
  const ids: (string | number)[] = []
  if (!recommendResponse) return ids
  recommendResponse.items.forEach(item => {
    const key = keyForResource(item)
    if (key) ids.push(key)
  })
  return ids
}

export const filterIdForFeed = (item: FeedItem | undefined) => {
  if (!item) return
  return keyForResource(item)
}

export const filterResourceIds = (resources: FeedItem[]) => {
  const ids: (string | number)[] = []

  resources.forEach(resource => {
    const key = keyForResource(resource)
    if (key) ids.push(key)
  })
  return ids
}

export const filterIdsForResponses: (recommendResponses: RecommendResponse[]) => (string | number)[] = r =>
  r.flatMap(filterIdsForResponse)

export function filterResourcesByType<T extends FeedItem['type']>(
  resources: (FeedItem | undefined)[] | undefined,
  type: T
): Extract<FeedItem, { type: T }>[] {
  if (!resources) return []

  return resources.filter((resource): resource is Extract<FeedItem, { type: T }> => resource?.type === type)
}

export function filterResourcesByTypes<T extends FeedItem['type']>(
  resources: (FeedItem | undefined)[] | undefined,
  types: APIResourceType[]
): Extract<FeedItem, { type: T }>[] {
  if (!resources) return []

  return resources.filter(
    (resource): resource is Extract<FeedItem, { type: T }> => resource != null && types.includes(resource.type)
  )
}

export function findResourceByType<T extends FeedItem['type']>(
  resource: FeedItem | undefined,
  type: T
): Extract<FeedItem, { type: T }> | null {
  if (!resource) return null

  if (resource.type === type) {
    return resource as Extract<FeedItem, { type: T }>
  }

  return null
}

export const filterResourcesForFeed = (resources: APIResource[]) => {
  return resources as FeedItem[]
}

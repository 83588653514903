/**
 * Stringifies object with circular references.
 *
 * @param object
 *
 * @example
 * const a = {}
 * a.b = a
 * JSON.stringify(a) // 💥💣 Error "Uncaught TypeError: Converting circular structure to JSON"
 * stringifyCircularObject(a) // returns '{"b":{}}'
 */
export const stringifyCircularObject = (object: object): string => {
  const cache = new Set()
  return JSON.stringify(object, (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (cache.has(value)) {
        return
      }
      cache.add(value)
    }
    return value
  })
}

export const clone = <T extends object>(object: T): T => {
  return typeof structuredClone === 'function' ? structuredClone(object) : JSON.parse(stringifyCircularObject(object))
}

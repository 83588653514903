import React, { ReactNode, useCallback } from 'react'

import { useTrackViewError } from '@hooks/analytics/useTrackViewError'
import { isServer } from '@lib/runtime-environment'
import Footer from './Footer'
import { MetaTags } from './MetaTags'
import SupportEmailLink from './SupportEmailLink'

interface ErrorPageProps {
  title: string
  description: string
  secondaryAction?: ReactNode
  disableTracking?: boolean
}

export const ErrorPage = ({ title, description, secondaryAction, disableTracking = false }: ErrorPageProps) => {
  useTrackViewError({ disabled: disableTracking })

  const retry = useCallback(() => {
    if (isServer()) return
    window.location.reload()
  }, [])

  return (
    <>
      <MetaTags title={title} description={description} />
      <div>
        <div className="h-screen w-screen">
          <div className="grid h-5/6 auto-cols-auto place-content-center place-items-center space-y-6 px-6 text-center">
            <h1 className="text-3xl font-medium">{title}</h1>
            <h2>{description}</h2>
            <button className="btn btn-secondary w-fit" onClick={retry}>
              Try again
            </button>
            {secondaryAction}
            <h4 className="pt-10 text-sm opacity-60">
              If you have an urgent issue, please contact us at{' '}
              <SupportEmailLink className="text-accent" subject="I had an error">
                <>support@mix.com</>
              </SupportEmailLink>
            </h4>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

import { original } from 'immer'
import isEqual from 'lodash.isequal'
import { useCallback, useEffect } from 'react'
import type { DraftFunction, Updater } from 'use-immer'
import { useImmer } from 'use-immer'
import { useEventCallback, useEventListener } from 'usehooks-ts'

import logger from '@lib/logger'
import { isServer } from '@lib/runtime-environment'
import runtimeConfig from '@lib/RuntimeConfig'

const errsTracked: Record<string, boolean> = {}
export const useLocalStorageImmer = <T>(key: string, initialValue: T): [T, Updater<T>] => {
  // Get from local storage then
  // parse stored json or return initialValue.
  const readValue = useCallback((): T => {
    // Prevent build error "window is undefined" but keeps working
    if (isServer()) {
      return initialValue
    }

    try {
      const item = window.localStorage.getItem(key)
      return item ? (parseJSON(item) as T) : initialValue
    } catch (error) {
      logger.warn(`Error reading localStorage key “${key}”:`, error)
      return initialValue
    }
  }, [initialValue, key])

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useImmer<T>(readValue)

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue: Updater<T> = useEventCallback((value: T | DraftFunction<T>) => {
    // Prevent build error "window is undefined" but keeps working
    if (isServer()) {
      logger.warn(`Tried setting localStorage key "${key}" even though environment is not a client`)
    }

    // Save state
    setStoredValue(previousValue => {
      const resolvedValue: T = (() => {
        if (value instanceof Function) {
          const modifiedValue: unknown = value(previousValue)
          const originalValue = original(previousValue)
          if (typeof modifiedValue !== 'undefined' && !isEqual(originalValue, previousValue)) {
            const errMessage = `[useLocalStorageImmer:${key}] An immer producer returned a new value *and* modified its draft. Either return a new value *or* modify the draft`
            if (runtimeConfig().publicRuntimeConfig.appEnv === 'prd') {
              if (!errsTracked[key]) {
                logger.error(errMessage)
                errsTracked[key] = true
              }
            } else {
              // eslint-disable-next-line no-debugger
              debugger
              // If you stop here: inspect the source function defined by the `value` and fix it .
              // See https://github.com/mix/mix-web/pull/756 for more info.
              throw new Error(errMessage)
            }
            // Safe use of 'as': The modifiedValue is not undefined
            return modifiedValue as T
          }
          // Safe use of 'as': The modifiedValue is either undefined or previousValue has stayed unchanged.
          return previousValue as T
        }
        return value
      })()
      try {
        // Save to local storage
        window.localStorage.setItem(key, JSON.stringify(resolvedValue))
      } catch (error) {
        logger.warn(`Error setting localStorage key “${key}”:`, error)
      }
      return resolvedValue
    })
  })

  useEffect(() => {
    setStoredValue(readValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleStorageChange = useCallback(
    (event: StorageEvent | CustomEvent) => {
      if ((event as StorageEvent).key && (event as StorageEvent).key !== key) {
        return
      }
      setStoredValue(readValue)
    },
    [key, readValue, setStoredValue]
  )

  // this only works for other documents, not the current one
  useEventListener('storage', handleStorageChange)

  // this is a custom event, triggered in writeValueToLocalStorage
  // See: useLocalStorage()
  useEventListener('local-storage', handleStorageChange)

  return [storedValue, setValue]
}

// A wrapper for "JSON.parse()"" to support "undefined" value
function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '')
  } catch {
    logger.error('parsing error on', { value })
    return undefined
  }
}

import axios, { AxiosError, AxiosRequestConfig } from 'axios'

import logger from '@lib/logger'
import { isServer } from '@lib/runtime-environment'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'
import { AppState } from '../store/store'

export const axiosBaseQuery =
  <T>(): BaseQueryFn<
    {
      config: AxiosRequestConfig
      payload?: T
    },
    T
  > =>
  async ({ config, payload }, api) => {
    try {
      const reqIP = (api.getState() as AppState).app.reqIP
      if (reqIP && isServer()) {
        config.headers = config.headers || {}
        config.headers['x-mix-client-ip'] = reqIP
      }
      const mergedConfig: AxiosRequestConfig = {
        ...config,
        data: {
          ...config.data,
          ...(payload ?? {}),
        },
      }

      const result = await axios.request(mergedConfig)

      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      logger.warn('axios error', err)
      return {
        error: {
          status: err.response?.status,
          data: {},
        },
      }
    }
  }

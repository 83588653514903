import axios from 'axios'
import toSafeInteger from 'lodash.tosafeinteger'
import Image from 'next/image'
import { useRef, useState } from 'react'
import toast from 'react-hot-toast'

import { useSegmentTrack } from '@hooks/analytics/useSegmentAnalytics'
import { useIsDesktop } from '@hooks/useIsDesktop'
import { useRequest } from '@hooks/useRequest'
import { IntoUser } from '@models/IntoUser'
import IntoAPI from '@services/IntoAPI'
import MixMix from '@services/MixMix'

export const InternalShareUsers = ({ urlID, onClose }: { urlID: string; onClose: () => void }) => {
  const formRef = useRef<HTMLFormElement | null>(null)
  const captionRef = useRef<HTMLInputElement | null>(null)
  const isDesktop = useIsDesktop()
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const [selectedUserIDs, setSelectedUserIDs] = useState<number[]>([])
  const { data } = useRequest(IntoAPI.user.recommendedLikers({ url_id: urlID }))
  const recommendedLikers = data?.items.filter((item): item is IntoUser => item.type === 'user')
  const track = useSegmentTrack()

  const handleChange = () => {
    if (formRef.current) {
      const formData = new FormData(formRef.current).values()
      const userIDs = Array.from(formData).map(u => toSafeInteger(u)) as unknown as number[]
      setSelectedUserIDs(userIDs)
      setIsSubmitDisabled(!userIDs?.length)
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    void axios
      .request(
        MixMix.share.shareWith({
          url_id: urlID,
          destinationUserIds: selectedUserIDs,
          caption: captionRef.current?.value,
        })
      )
      .then(() => {
        toast.success('Sent')
        formRef.current?.reset()
        setSelectedUserIDs([])
        setIsSubmitDisabled(true)
        track('internalShare')
        onClose()
      })
      .catch(() => toast.error(`Failed to send`))
  }

  if (!recommendedLikers?.length) return null
  return (
    <form ref={formRef} className="flex flex-col space-y-4" onSubmit={handleSubmit}>
      <div className={`flex overflow-x-scroll ${isDesktop ? 'px-4' : '-ml-4 scale-90 px-0'} py-2 scrollbar-hide`}>
        {recommendedLikers.map(user => (
          <label
            key={user.user_id}
            className="flex h-full w-20 shrink-0 cursor-pointer flex-col items-center whitespace-nowrap"
            htmlFor={`user-${user.user_id}`}
          >
            <input
              className="peer hidden"
              type="checkbox"
              id={`user-${user.user_id}`}
              name={user.username}
              value={user.user_id}
              onChange={handleChange}
            />
            <Image
              className="size-11 rounded-full border-2 border-transparent outline-red-700 hover:scale-105 peer-checked:border-accent"
              src={user.photo_url ?? ''}
              alt={user.username}
              width={1}
              height={1}
            />
            <div className="mt-2 whitespace-normal px-2 text-center text-xs">{user.display_name}</div>
          </label>
        ))}
      </div>
      {!isSubmitDisabled && (
        <div className="mx-4 flex flex-col space-y-2 font-medium">
          <input
            ref={captionRef}
            autoFocus={true}
            autoCapitalize={'sentences'}
            className="rounded-md bg-black/20 p-2 outline-none placeholder:text-sm placeholder:text-white/60"
            placeholder="Add a private message..."
          ></input>
          <button type="submit" className="rounded-md bg-accent p-2" disabled={isSubmitDisabled}>
            {selectedUserIDs?.length > 1 ? `Send to ${selectedUserIDs?.length} people separately` : 'Send'}
          </button>
        </div>
      )}
    </form>
  )
}

import React from 'react'

const ExpandGemsCopy = {
  heading: 'Expand your mind',
  subheading: 'Discover the gems of the web, personalized to you.',
}

const ExpandGemsElement = () => (
  <>
    <h1 className="mb-12 text-6xl font-bold tracking-tight text-white md:text-7xl lg:text-8xl 2xl:mb-12 2xl:text-[120px]">
      Expand <br /> your mind
    </h1>
    <h2 className="mx-auto mb-10 w-80 text-xl font-semibold leading-tight text-white opacity-60 sm:w-full lg:text-2xl 2xl:text-3xl">
      Discover the gems of the web, personalized to you.
    </h2>
  </>
)

const ExpandGems = { element: ExpandGemsElement, copy: ExpandGemsCopy }

export default ExpandGems

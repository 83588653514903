import { InternalAxiosRequestConfig } from 'axios'

let commandContextInterceptorImpl = (config: InternalAxiosRequestConfig) => config
let sessionIDInterceptorImpl = (config: InternalAxiosRequestConfig) => config

export const requestInterceptor = (config: InternalAxiosRequestConfig) => {
  return sessionIDInterceptorImpl(commandContextInterceptorImpl(config))
}

export const overrideSessionIDInterceptor = (newValue?: typeof commandContextInterceptorImpl) => {
  if (newValue) sessionIDInterceptorImpl = newValue
  else sessionIDInterceptorImpl = config => config
}
export const overrideCommandContextInterceptor = (newValue?: typeof commandContextInterceptorImpl) => {
  if (newValue) commandContextInterceptorImpl = newValue
  else commandContextInterceptorImpl = config => config
}

import axios, { HttpStatusCode } from 'axios'
import isEqual from 'lodash.isequal'
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { useImmer } from 'use-immer'
import { useLocalStorage } from 'usehooks-ts'

import { useResetSegmentUser, useSegmentIdentify } from '@hooks/analytics/useSegmentAnalytics'
import { useRequest } from '@hooks/useRequest'
import { flushStoredEvents } from '@lib/LocalEventStorage'
import logger from '@lib/logger'
import { IntoUser } from '@models/IntoUser'
import * as Sentry from '@sentry/nextjs'
import IntoAPI from '@services/IntoAPI'
import MixMix from '@services/MixMix'

interface CurrentUserContextProps {
  isLoggedIn: boolean
  currentUserID: number | undefined
  currentUser: IntoUser | undefined
  refreshCurrentUser: (() => void) | undefined
}

const CurrentUserContext = createContext<CurrentUserContextProps | undefined>(undefined)

export const CurrentUserContextProvider = ({
  isLoggedInFromCookies,
  children,
}: {
  isLoggedInFromCookies: boolean
  children: ReactNode
}) => {
  const resetSegmentUser = useResetSegmentUser()
  const [isLoggedIn, setIsLoggedIn] = useState(isLoggedInFromCookies)
  const [currentUser, setCurrentUser] = useLocalStorage<IntoUser | undefined>('current_user', undefined)
  const [currentUserID, setCurrentUserID] = useState(currentUser?.user_id)
  const [contextValue, setContextValue] = useImmer<CurrentUserContextProps>({
    isLoggedIn,
    currentUser,
    currentUserID,
    refreshCurrentUser: undefined,
  })
  const identify = useSegmentIdentify({ currentUser, isLoggedIn })

  const changeCurrentUserIfDifferent = useCallback(
    (newUser: IntoUser) => {
      if (isEqual(newUser, currentUser)) return
      setCurrentUser(newUser)
      setCurrentUserID(newUser.user_id)
    },
    [currentUser, setCurrentUser]
  )

  const resetCurrentUser = useCallback(async () => {
    setCurrentUser(undefined)
    setCurrentUserID(undefined)
    setIsLoggedIn(false)
    resetSegmentUser()
    window.branch?.logout?.()

    await axios.request(MixMix.logout()).catch(logger.warn.bind(logger))
  }, [setCurrentUser, resetSegmentUser])

  const { mutate } = useRequest(isLoggedIn ? IntoAPI.user.getCurrentUserDetails() : null, {
    onSuccess: ({ data: currentUser }) => {
      changeCurrentUserIfDifferent(currentUser)
      identify()
      window.branch?.setIdentity?.(currentUser.user_id.toString())
      void flushStoredEvents(currentUser.user_id)
    },
    onError: async err => {
      // 401 when getting the current user
      if (err.response?.status === HttpStatusCode.Unauthorized || err.response?.status === HttpStatusCode.BadRequest) {
        await resetCurrentUser()
      } else {
        logger.error(err)
      }
    },
    fallbackData: currentUser,
    revalidateOnMount: isLoggedIn,
    revalidateOnReconnect: isLoggedIn,
    revalidateOnFocus: false,
    revalidateIfStale: false,
  })

  const refreshCurrentUser = useCallback(() => void mutate(), [mutate])

  useEffect(() => {
    setContextValue(draft => {
      draft.isLoggedIn = isLoggedIn
      if (!isEqual(currentUser, draft.currentUser)) {
        draft.currentUser = currentUser
      }
      draft.currentUserID = currentUserID
      draft.refreshCurrentUser = refreshCurrentUser
    })
  }, [isLoggedIn, currentUser, currentUserID, refreshCurrentUser, setContextValue])

  useEffect(() => {
    if (!isLoggedIn && currentUserID) void resetCurrentUser()
  }, [isLoggedIn, currentUserID, resetCurrentUser])

  useEffect(() => {
    identify()
  }, [identify, currentUser])

  useEffect(() => {
    Sentry.setUser(currentUserID ? { id: currentUserID } : null)
  }, [currentUserID])

  return <CurrentUserContext.Provider value={contextValue}>{children}</CurrentUserContext.Provider>
}

export const useCurrentUserContext = () => {
  const result = useContext(CurrentUserContext)
  if (!result) {
    throw new Error('Context used outside of its Provider!')
  }
  return result
}

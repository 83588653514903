import { useCallback } from 'react'

import { keyForResource } from '@models/APIResource'
import RecommendResponse, { filterResourcesByType } from '@models/RecommendResponse'
import feedApi from '../api/feedApi'
import { selectMediaTypes } from '../slices/appSlice'
import { feedConstants, selectCurrentFeed } from '../slices/feedSlice'
import { useAppDispatch, useAppSelector } from '../store/store'
import { isDefined } from './injectsUrlsIntoFeed'

export const useInjectUrlsIntoFeed = () => {
  const dispatch = useAppDispatch()
  const mediaTypes = useAppSelector(selectMediaTypes)
  const { _instanceId, identifier, currentFeedItemKey } = useAppSelector(state => state.feed)
  const currentFeed = useAppSelector(selectCurrentFeed)

  return useCallback(
    async (context: RecommendResponse | undefined) => {
      const { meta, items: exploitResources } = context ?? {}
      const insertAfter = meta?.insertAfter
      const exploitUrls = filterResourcesByType(exploitResources, 'url')

      if (exploitUrls?.length > 0 && insertAfter) {
        const currentIndex = currentFeed?.items.findIndex(item => {
          const key = keyForResource(item)
          return key === currentFeedItemKey
        })

        const filterIds = [...(currentFeed?.items ?? [])]
          .map(item => {
            if (item.type === 'url') {
              return item.url_id
            }
          })
          .filter(isDefined)

        const feedArgs = {
          page: 0,
          pageSize: feedConstants.FULL_SCREEN_PAGE_SIZE,
          contextId: currentFeedItemKey,
          slug: identifier.slug,
          mediaTypes: Array.from(mediaTypes),
          filterIds,
          isFollowable: identifier.isFollowable,
          _instanceId,
        }

        if (currentIndex !== undefined && currentIndex !== -1 && !filterIds.includes(exploitUrls[0].url_id)) {
          dispatch(
            feedApi.util.updateQueryData('getFeedData', feedArgs, draft => {
              draft?.items.splice(currentIndex + insertAfter, 0, ...exploitUrls)
            })
          )
        }
      }
    },
    [dispatch, mediaTypes, _instanceId, identifier, currentFeed, currentFeedItemKey]
  )
}

import { useCallback } from 'react'

import { useCurrentUserContext } from '@context/CurrentUserContext'
import urlApi from '@redux/api/urlApi'

export const useToggleRating = (itemId: string | number | null) => {
  const { currentUser } = useCurrentUserContext()
  const [likeUrl] = urlApi.useLikeUrlMutation()
  const [disLikeUrl] = urlApi.useDislikeUrlMutation()
  const [clearUrlLikes] = urlApi.useClearLikesMutation()

  const rateUrlUp = useCallback(async () => {
    if (!currentUser || !itemId || typeof itemId !== 'string') return
    await likeUrl({ urlId: itemId, user: currentUser })
  }, [itemId, currentUser, likeUrl])

  const rateUrlDown = useCallback(async () => {
    if (!currentUser || !itemId || typeof itemId !== 'string') return
    await disLikeUrl({ urlId: itemId, user: currentUser })
  }, [itemId, currentUser, disLikeUrl])

  const rateUrlNone = useCallback(async () => {
    if (!currentUser || !itemId || typeof itemId !== 'string') return
    await clearUrlLikes({ urlId: itemId, user: currentUser })
  }, [itemId, currentUser, clearUrlLikes])

  return { rateUrlDown, rateUrlNone, rateUrlUp }
}

import React, { useEffect, useState } from 'react'

import classes from '@styles/Home.module.css'

const contentList = ['stuff', 'images', 'videos', 'articles', 'gifs', 'memes', 'designs', 'fashion']
const CONTENT_CYCLE_INTERVAL = 2000

const TaglineContentRotator = ({ content }: { content: string }) => (
  <>
    <span className={`${classes.animateFlyShort} relative inline-block proportional-nums`}>{content}</span>{' '}
  </>
)

const ExpandTagline = () => {
  const [contentIndex, setContentIndex] = useState(0)
  useEffect(() => {
    const contentTypeInterval = setInterval(() => {
      setContentIndex(contentIndex >= contentList?.length - 1 ? 0 : contentIndex + 1)
    }, CONTENT_CYCLE_INTERVAL)
    return () => {
      clearInterval(contentTypeInterval)
    }
  }, [contentIndex])
  return (
    <>
      <h1 className="mb-8 text-6xl font-bold tracking-tight md:text-7xl lg:text-8xl 2xl:mb-12 2xl:text-[120px]">
        Expand <br /> your mind
      </h1>{' '}
      <h2 className="mb-10 text-lg leading-tight opacity-50 lg:text-xl 2xl:text-2xl">
        <span>The coolest&nbsp;</span>
        <TaglineContentRotator content={contentList[contentIndex]} />
        <span>
          on the internet, <br className="md:hidden" /> curated by people like you.
        </span>
      </h2>
    </>
  )
}

const ExpandCopy = {
  heading: 'Expand your mind',
  subheading: 'The coolest stuff on the internet, curated by people like you.',
}

const Expand = { element: ExpandTagline, copy: ExpandCopy }

export default Expand

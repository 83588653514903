import Script from 'next/script'
import React from 'react'

import { useGlobalContext } from '@context/GlobalContexts'
import runtimeConfig from '../RuntimeConfig'

export const GoogleAnalytics = () => {
  const { trackingID } = runtimeConfig().publicRuntimeConfig.analytics.googleAnalytics
  const { browserInfo } = useGlobalContext()
  if (browserInfo.browser.bot) return null
  return (
    <>
      <Script src={`https://www.googletagmanager.com/gtm.js?id=${trackingID}`} strategy="afterInteractive" />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'gtm.start': new Date().getTime(),
            'event': 'gtm.js'
          })
        `}
      </Script>
    </>
  )
}

import React, { Component, ErrorInfo, ReactNode } from 'react'

import { Notifier } from '@airbrake/browser'
import { clone } from '@lib/JSONUtils'
import runtimeConfig from '@lib/RuntimeConfig'
import InternalServerErrorPage from '@pages/500.page'
import * as Sentry from '@sentry/react'

interface Props {
  children?: ReactNode
  fallbackComponent?: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }
  private airbrake: Notifier

  constructor(props: Props) {
    super(props)
    this.airbrake = new Notifier(clone(runtimeConfig().publicRuntimeConfig.airbrake))
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo)

    // Report to Airbrake.
    void this.airbrake.notify({
      error,
      params: { errorInfo },
    })

    // Report to Sentry.
    Sentry.captureException(error, {
      tags: { userVisible: true },
      extra: { errorInfo },
    })
  }

  public render() {
    if (this.state.hasError) {
      return this.props.fallbackComponent ?? <InternalServerErrorPage />
    }

    return this.props.children
  }
}

export default ErrorBoundary

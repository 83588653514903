import React, { createContext, ReactNode, useContext, useState } from 'react'

import BottomSheet from '@mobile/BottomSheet'

const BottomSheetContext = createContext<{
  openBottomSheet: (content: ReactNode) => void
  closeBottomSheet: () => void
}>({
  openBottomSheet: () => {},
  closeBottomSheet: () => {},
})

export const BottomSheetProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [content, setContent] = useState<ReactNode | null>(null)

  const openBottomSheet = (content: ReactNode) => {
    setContent(content)
    setIsOpen(true)
  }

  const closeBottomSheet = () => {
    setIsOpen(false)
    setContent(null)
  }

  return (
    <BottomSheetContext.Provider value={{ openBottomSheet, closeBottomSheet }}>
      {children}
      <BottomSheet isOpen={isOpen} closeSheet={closeBottomSheet}>
        {content}
      </BottomSheet>
    </BottomSheetContext.Provider>
  )
}

export const useBottomSheet = () => useContext(BottomSheetContext)

/* eslint-disable @typescript-eslint/no-explicit-any */

import MixMix from '@services/MixMix'
import { processAxiosInterceptors } from '@tracking/broadcast/broadcastHelpers'
import { Commands } from '@tracking/broadcast/trackingMessage'
import { postMessageToServiceWorker } from '@tracking/communicator/worker'
import { decodeMessage } from '@tracking/utils/protobuf'
import logger from './logger'

const MAX_STORED_EVENTS = 4096
const STORAGE_KEY = 'mixLocalEvents'

interface StoredEvent {
  messageType: string
  encodedMessage: string
}

export function addEvent(messageType: string, encodedMessage: any): void {
  const events = getStoredEvents()

  events.push({ messageType, encodedMessage })

  if (events?.length > MAX_STORED_EVENTS) {
    events.shift()
  }
  setStoredEvents(events)
}

export function getAndClearEvents(): StoredEvent[] {
  const events = getStoredEvents()
  localStorage.removeItem(STORAGE_KEY)
  return events
}

export async function flushStoredEvents(userId: number) {
  if (typeof window === 'undefined') return

  const storedEvents = getAndClearEvents()

  for (const event of storedEvents) {
    try {
      const message = decodeMessage(event.messageType, event.encodedMessage)
      message.userId = userId

      const messageType = event.messageType as Commands
      const command = messageType[0].toLowerCase() + messageType.slice(1)
      const initialConfig = MixMix.commands[command as unknown as keyof typeof MixMix.commands]({} as any)
      const config = processAxiosInterceptors(initialConfig)

      const serializedConfig = { ...config, data: message.toJSON() }
      logger.info(`${messageType} Data:`, serializedConfig.data)
      await postMessageToServiceWorker(messageType, serializedConfig)
    } catch (error) {
      logger.warn(`Error processing ${event.messageType} event:`, error)
    }
  }
}

function getStoredEvents(): StoredEvent[] {
  try {
    const storedData = localStorage.getItem(STORAGE_KEY)
    return storedData ? JSON.parse(storedData) : []
  } catch (error) {
    logger.warn(`Error getting stored events:`, error)
    return []
  }
}

function setStoredEvents(events: StoredEvent[]): void {
  try {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(events))
  } catch (error) {
    logger.warn(`Error setting stored events:`, error)
  }
}

import isString from 'lodash.isstring'
import { Middleware, SWRHook } from 'swr'

export const commandContextSWRMiddleware: Middleware = (useSWRNext: SWRHook) => (key, fetcher, config) => {
  let serializedKey = key
  if (isString(serializedKey) && (serializedKey as string).includes('eventTimestamp')) {
    // ,"eventTimestamp":"1234" => '' -> last or middle
    // "eventTimestamp":"1234", => '' -> first or middle
    serializedKey = serializedKey.replace(/(,"eventTimestamp":"\d+")/, '')
    serializedKey = serializedKey.replace(/("eventTimestamp":"\d+",)/, '')
  }
  return useSWRNext(serializedKey, fetcher, config)
}

import Link from 'next/link'
import React, { useCallback, useMemo, useState } from 'react'
import { useIsomorphicLayoutEffect } from 'usehooks-ts'

import Modal from '@common/Modal'
import { useCurrentUserContext } from '@context/CurrentUserContext'
import { useTrackClick } from '@hooks/analytics/useTrackClick'
import { useIsDesktop } from '@hooks/useIsDesktop'
import IconInstagram from '@icons/IconInstagram.svg'
import IconTwitter from '@icons/IconTwitter.svg'
import runtimeConfig from '@lib/RuntimeConfig'
import UrlHelper from '@lib/UrlHelper'
import LoginButton from './login/LoginButton'
import { TweaksModal } from './TweaksModal'

export const ContextMenu = (props: {
  position: 'topRight' | 'bottomLeft'
  isVisible?: boolean
  toggleVisibility?: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { isVisible, toggleVisibility: toggleMobileVisibilty } = props
  const { isLoggedIn, currentUser } = useCurrentUserContext()
  const showSocialIcons = Boolean(0) // hack to keep the ui around in case someone wants to.
  const [isTweaksAllowed, setIsTweaksAllowed] = useState(false)
  const [isTweaksModalVisible, setIsTweaksModalVisible] = useState(false)
  const [currentUserPath, setCurrentUserPath] = useState<string | undefined>(undefined)
  const isMobile = !useIsDesktop()
  const handleOnClick = useCallback(() => {
    if (isMobile) {
      toggleMobileVisibilty?.(prev => !prev)
    }
  }, [toggleMobileVisibilty, isMobile])
  const trackClick = useTrackClick()

  const buildInfo: { title: string; link?: string } | undefined = useMemo(() => {
    if (!isTweaksAllowed) return undefined
    const hash = (() => {
      switch (runtimeConfig().publicRuntimeConfig.appEnv) {
        case 'prd':
        case 'stg':
          return runtimeConfig().publicRuntimeConfig.buildID
        case 'canary':
          return runtimeConfig().publicRuntimeConfig.buildID.split('.').pop()
        case 'dev':
          return
      }
    })()
    return {
      title: `v${runtimeConfig().publicRuntimeConfig.buildID} (${runtimeConfig().publicRuntimeConfig.buildNumber})`,
      link: hash ? `https://github.com/mix/mix-web/tree/${hash}` : undefined,
    }
  }, [isTweaksAllowed])

  const [isLogoutModalVisible, setLogoutModalVisible] = useState(false)

  useIsomorphicLayoutEffect(() => {
    setIsTweaksAllowed(currentUser?.isEmployee === true || runtimeConfig().publicRuntimeConfig.appEnv === 'dev')
    if (currentUser?.username) setCurrentUserPath(UrlHelper.userPath(currentUser.username))
  }, [currentUser?.isEmployee, currentUser?.username])

  return (
    <div
      className={[
        `absolute z-50 w-auto flex-col space-y-1 whitespace-nowrap rounded-lg bg-menu p-2 text-menu duration-500 sm:group-hover:flex ${
          isVisible ? 'flex' : 'hidden'
        }`,
        props.position === 'topRight'
          ? 'right-1 top-full mt-1 animate-fade-in'
          : 'bottom-0 left-full ml-1 animate-fade-in',
      ].join(' ')}
      onClick={handleOnClick}
    >
      <div className="flex flex-col space-y-2 p-2 pt-3">
        {isLoggedIn ? (
          <>
            <Link href="/" className="btn btn-link text-left text-sm sm:hidden">
              Home
            </Link>
            {currentUserPath && (
              <Link href={currentUserPath} className="btn btn-link text-left text-sm">
                Profile
              </Link>
            )}
            <Link href={UrlHelper.settingsPath()} className="btn btn-link text-left text-sm">
              Settings
            </Link>

            <button className="btn btn-link text-left text-sm" onClick={() => setLogoutModalVisible(true)}>
              Logout
            </button>
            <Modal isVisible={isLogoutModalVisible} onClose={() => setLogoutModalVisible(false)}>
              <div className="z-10 flex flex-col space-y-3 rounded bg-menu p-6 text-menu">
                <div className="text-2xl font-semibold">Just to confirm,</div>
                <div className="text-sm">Would you like to log out?</div>
                <div className="flex justify-center space-x-6 pt-6">
                  <button className="btn bg-primary/10" onClick={() => setLogoutModalVisible(false)}>
                    Cancel
                  </button>
                  <a href={'/logout'} onClick={() => trackClick('logout')} className="btn btn-secondary">
                    Log out
                  </a>
                </div>
              </div>
            </Modal>
          </>
        ) : (
          <>
            <Link href={'/'} className="btn btn-link text-left text-sm">
              Home
            </Link>
            <LoginButton copyVariant={'login'} className="btn btn-link text-left text-sm" showModalOnNative={true}>
              Login
            </LoginButton>
          </>
        )}
        {isTweaksAllowed ? (
          <>
            <button className="btn btn-link text-left text-sm" onClick={() => setIsTweaksModalVisible(true)}>
              Tweaks
            </button>
            <Link href={'/submission-queue'} className="btn btn-link text-left text-sm">
              Submission Queue
            </Link>
            <Link href={'/playground'} className="btn btn-link text-left text-sm">
              Into Playground
            </Link>
            <TweaksModal onClose={() => setIsTweaksModalVisible(false)} isVisible={isTweaksModalVisible} />
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="flex flex-col space-y-2 p-2">
        <a
          className="btn btn-link text-left text-sm"
          target=""
          href="mailto:support@mix.com"
          onClick={() => trackClick('contactSupport')}
        >
          Contact support
        </a>
        <Link href={'/tos'} className="btn btn-link text-left text-sm">
          Terms
        </Link>
        <Link href={'/privacy'} className="btn btn-link text-left text-sm">
          Privacy
        </Link>
      </div>
      {showSocialIcons && (
        <div className="flex items-center">
          <a href="https://twitter.com/getmixapp" className="btn btn-square text-menu" target="_blank" rel="noreferrer">
            <IconTwitter />
          </a>{' '}
          <a
            href="https://www.instagram.com/getmixapp/"
            className="btn btn-square text-menu"
            target="_blank"
            rel="noreferrer"
          >
            <IconInstagram />
          </a>
        </div>
      )}
      <span className="p-2 text-xs opacity-50">
        <span>© {new Date().getFullYear()} - Mix is an</span>{' '}
        <a className="btn btn-link" href="https://expa.com/" target="_blank" rel="noreferrer">
          Expa
        </a>{' '}
        <span>company</span>
      </span>
      {buildInfo && (
        <a
          className={[
            'border-t-[0.5px] border-contrast/20 p-2 text-right text-xs opacity-80',
            buildInfo.link ? 'hover:font-medium hover:text-accent' : 'cursor-auto',
          ].join(' ')}
          href={buildInfo.link}
          target="_blank"
        >
          {buildInfo.title}
        </a>
      )}
    </div>
  )
}

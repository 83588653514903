import { APIResource } from './APIResource'

export namespace IntoNode {
  export const FOR_YOU: IntoNode = {
    type: 'node',
    slug: 'for-you',
    name: 'For You',
    isFollowing: false,
    isFollowable: false,
    meta: {},
  }
  export const POPULAR: IntoNode = {
    type: 'node',
    slug: 'new-user-recs',
    name: 'Popular',
    isFollowing: false,
    isFollowable: false,
    meta: {},
  }
  export const MY_INTERESTS: IntoNode = {
    type: 'node',
    slug: 'for-you-interests',
    name: 'My Topics',
    isFollowing: false,
    isFollowable: false,
    meta: {},
  }
  export const SAMPLING: IntoNode = {
    type: 'node',
    slug: 'sampling',
    name: 'Recent Posts',
    isFollowing: false,
    isFollowable: false,
    meta: {},
  }
  export const RELATED: IntoNode = {
    type: 'node',
    slug: 'related',
    name: 'Related',
    isFollowing: false,
    isFollowable: false,
    meta: {},
  }
  export const FEATURED: IntoNode = {
    type: 'node',
    slug: 'web-homepage',
    name: 'Web Homepage',
    isFollowing: false,
    isFollowable: false,
    meta: {},
  }
  export const SAVED: IntoNode = {
    type: 'node',
    slug: 'saved',
    name: 'Saved',
    isFollowing: false,
    isFollowable: false,
    meta: {},
  }
  export const USER: IntoNode = {
    type: 'node',
    slug: 'user',
    name: 'User',
    isFollowing: false,
    isFollowable: false,
    meta: {},
  }
}

export interface IntoNode extends APIResource {
  type: 'node'
  // Unique slug of this node
  slug: string
  // Display name of this node
  name: string
  // Description for this node
  description?: string
  // Array of thumbnails for this node
  thumbnails?: { thumbnail?: string; tiny_thumbnail?: string }[]
  // Can this node be followed?
  // - Hide the follow button if this is false
  // - Resolve to a 404 page if this is false
  isFollowable: boolean
  // Is the current user following this node?
  isFollowing: boolean
  meta: {
    // Group name if this was recommended as part of sets of topics
    group?: string
    // Is this node blocked by the current user?
    isBlocked?: boolean
    // Is this a channel (i.e. a source)?
    // Example: "Reddit: r/cats"
    isChannel?: boolean
    // Children for onboarding topic related
    children?: IntoNode[]
  }
}

import Image from 'next/image'
import Link from 'next/link'
import React, { useEffect, useMemo, useState } from 'react'

import { FollowButton } from '@common/FollowButton'
import { useCurrentUserContext } from '@context/CurrentUserContext'
import IconArrowMin from '@icons/IconArrowMin.svg'
import IconSideTriangle from '@icons/IconSideTriangle.svg'
import UrlHelper from '@lib/UrlHelper'
import { IntoNode } from '@models/IntoNode'
import { useToggleNodeFollow } from '@pages/node/hooks/useToggleNodeFollow'
import { useToggleUserFollow } from '@pages/user/hooks/useToggleUserFollow'
import nodeApi from '@redux/api/nodeApi'
import userApi from '@redux/api/userApi'
import { selectUserId } from '@redux/slices/userSlice'
import { useAppSelector } from '@redux/store/store'
import { ToolbarTopicSwitcher } from './ToolbarTopicSwitcher'

const ToolbarFeedTitleTopic = ({
  identifier,
  isDropdownEnabled = true,
}: {
  identifier: IntoNode
  isDropdownEnabled?: boolean
}) => {
  const { data: node } = nodeApi.useGetNodeDetailsQuery({ slug: identifier.slug })
  const handleFollow = useToggleNodeFollow(node)

  if (!node) return <></>
  return (
    <div className="flex w-full min-w-0 items-center space-x-2">
      <div className="flex min-w-0 items-center space-x-4">
        {isDropdownEnabled ? (
          <ToolbarTopicSwitcher identifier={identifier} />
        ) : (
          <div className="group z-50 flex shrink-0 px-3 py-2 text-white outline-none">
            <IconArrowMin className="size-7 -scale-x-100 p-2 pr-0 opacity-50" />
            <div className="min-w-0 whitespace-nowrap text-lg font-semibold text-white">{identifier.name}</div>
          </div>
        )}
        {node.isFollowable && (
          <FollowButton
            className="flex w-fit items-center py-2"
            isFollowing={node.isFollowing}
            onFollow={handleFollow}
          />
        )}
      </div>
    </div>
  )
}

const ToolbarFeedTitleUser = () => {
  const userId = useAppSelector(selectUserId)
  const { data: user } = userApi.useGetUserDetailsQuery({ userId: userId ?? 0 }, { skip: !userId })
  const { isLoggedIn, currentUserID } = useCurrentUserContext()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isOwnProfile = useMemo(() => currentUserID === user?.user_id, [isLoggedIn, currentUserID, user?.user_id])
  const handleFollow = useToggleUserFollow(user)

  const [hasMounted, setHasMounted] = useState(false)
  useEffect(() => setHasMounted(true), [])

  if (!user) return <></>
  return (
    <div className="flex w-full min-w-0 items-center space-x-2">
      <div className={`box-border text-primary opacity-50`}>
        <IconSideTriangle />
      </div>
      <div className="flex min-w-0 items-center space-x-4">
        <Link
          href={UrlHelper.userPath(user.username)}
          className="flex w-full min-w-0 items-center space-x-2 hover:underline"
        >
          <Image
            className="aspect-square size-10 rounded-full"
            src={user.photo_url ?? ''}
            alt={user.display_name}
            width={40}
            height={40}
          />
          <div className="truncate text-lg font-semibold">{`@${user.username}`}</div>
        </Link>
        {/* Only render after mounting to prevent flicker for isOwnProfile*/}
        {!isOwnProfile && hasMounted && (
          <FollowButton
            isFollowing={user.meta.isLiked ?? false}
            onFollow={handleFollow}
            className="flex h-9 items-center py-2"
          />
        )}
      </div>
    </div>
  )
}

interface ToolbarTitleProps {
  isSmallScreen: boolean
  identifier: IntoNode
  isDropdownEnabled?: boolean
}

export const ToolbarTitle: React.FC<ToolbarTitleProps> = ({ isSmallScreen, isDropdownEnabled, identifier }) => {
  if (isSmallScreen) {
    return null
  }

  if (identifier.slug === 'user') {
    return <ToolbarFeedTitleUser />
  }

  return <ToolbarFeedTitleTopic isDropdownEnabled={isDropdownEnabled} identifier={identifier} />
}

import Modal from '@common/Modal'
import { useCurrentUserContext } from '@context/CurrentUserContext'
import { useIsDesktop } from '@hooks/useIsDesktop'
import { IntoUrl } from '@models/IntoUrl'
import ExternalShareButtons from './ExternalShareButtons'
import { InternalShareUsers } from './InternalShareUsers'

interface ShareModalProps {
  url: IntoUrl
  isVisible: boolean
  onClose: () => void
}
export const UrlShareModal = ({ url, isVisible, onClose }: ShareModalProps) => {
  const { isLoggedIn } = useCurrentUserContext()
  const isMobile = !useIsDesktop()
  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <div
        className={`relative z-10 flex max-h-screen flex-col rounded-lg bg-secondary p-0 scrollbar-hide selection:bg-accent/20 light:bg-menu ${isMobile ? 'w-[90vw] space-y-1 pb-1 pt-4' : 'space-y-6 py-4 sm:w-fit sm:max-w-2xl'}`}
      >
        <div className="mb-2 text-center font-semibold">Share via</div>
        <ExternalShareButtons url={url} onClose={onClose} />
        {isLoggedIn && <InternalShareUsers key={url.url_id} urlID={url.url_id} onClose={onClose} />}
      </div>
    </Modal>
  )
}

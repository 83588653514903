import { createRouterMiddleware } from 'connected-next-router'
import { createWrapper, MakeStore } from 'next-redux-wrapper'
import { useDispatch, useSelector } from 'react-redux'
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist'

import runtimeConfig from '@lib/RuntimeConfig'
import { configureStore, Store, ThunkAction, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit'
import { mixApi } from '../api/root'
import { rootReducer } from './rootReducer'

export type RootState = ReturnType<typeof rootReducer>
const isDev =
  runtimeConfig().publicRuntimeConfig.appEnv === 'dev' || runtimeConfig().publicRuntimeConfig.nodeEnv === 'development'

let store: ReturnType<typeof configStore>

const configStore = () =>
  configureStore({
    reducer: rootReducer,
    devTools: isDev,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      })
        .concat(mixApi.middleware)
        .concat(createRouterMiddleware()),
  })
const makeStore: MakeStore<Store<RootState>> = () => {
  store = configStore()
  return store
}

export type AppStore = ReturnType<typeof makeStore>
export type AppState = ReturnType<AppStore['getState']>
export type AppDispatch = ThunkDispatch<AppState, unknown, UnknownAction> &
  (<R>(asyncAction: ThunkAction<R, AppState, unknown, UnknownAction>) => R)

export const wrapper = createWrapper<Store<RootState>>(makeStore, {
  debug: isDev,
})

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()
export { store }

import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useIsomorphicLayoutEffect, useLocalStorage, useSessionStorage } from 'usehooks-ts'

import { useCurrentUserContext } from '@context/CurrentUserContext'
import { useGlobalContext } from '@context/GlobalContexts'
import { useTrackClick } from '@hooks/analytics/useTrackClick'
import { getDeeplinkQueryString } from '@hooks/useDeeplinkInfo'
import { useIsDesktop } from '@hooks/useIsDesktop'
import IconPlus from '@icons/IconPlus.svg'
import runtimeConfig from '@lib/RuntimeConfig'
import UrlHelper from '@lib/UrlHelper'
import { selectShowGSignIn, setShowGSignIn } from '@redux/slices/appSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'
import DownloadOptions from './DownloadOptions'

const classNameHidden = '-bottom-96 opacity-0'
const classNameVisible = 'bottom-auto'

const SHOW_DELAY = 1000
const STORAGE_KEY = 'hide_peekaboo_panel'
export const PEEKABOO_EXEMPT_ROUTES = ['/signup', '/setup/onboarding', '/timecapsule']

const GSignInPanel = () => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const showGSignIn = useAppSelector(selectShowGSignIn)

  useEffect(() => {
    if (router.route !== '/home/Home') {
      setTimeout(() => {
        dispatch(setShowGSignIn(true))
      }, SHOW_DELAY)
    }
  }, [router.route, dispatch])

  return (
    <div
      id="g_id_onload"
      className={`-mr-2 transition-all duration-500 [&>div]:!-bottom-[8px] [&>div]:!-right-[8px] [&>div]:!top-auto ${
        showGSignIn ? 'opacity-100' : '!h-0 overflow-hidden opacity-0'
      }`}
      data-prompt_parent_id="g_id_onload"
      data-client_id={runtimeConfig().publicRuntimeConfig.auth.google.clientID}
      data-context="signin"
      data-login_uri={UrlHelper.auth.googleIDToken()}
      data-ux-mode="popup"
      data-auto_select="false"
      data-auto_prompt="true"
      data-itp_support="true"
      data-cancel_on_tap_outside="false"
    />
  )
}

const appDownloadURLs = {
  loggedOut: {
    google: 'https://link.mix.com/qVrMvKzhGJb',
    apple: 'https://link.mix.com/e/TGc151ehGJb',
  },
  loggedIn: {
    google: 'https://link.mix.com/bErCUTcyvNb',
    apple: 'https://link.mix.com/e/1iuaR9iyvNb',
  },
}

export const DownloadAppPeekabooPanel = () => {
  const isDesktop = useIsDesktop()
  const { pathname } = useRouter()
  const { isLoggedIn } = useCurrentUserContext()
  const [isPeekabooHiddenFE, setIsPeekabooHiddenFE] = useState(true)
  const [isPeekabooHiddenLoggedOut, setIsPeekabooHiddenLoggedOut] = useSessionStorage(STORAGE_KEY, false)
  const [isPeekabooHiddenLoggedIn, setIsPeekabooHiddenLoggedIn] = useLocalStorage(STORAGE_KEY, false)
  const trackClick = useTrackClick()

  const containerRef = useRef<HTMLDivElement | null>(null)

  useIsomorphicLayoutEffect(() => {
    // Only change visibility of peekaboo panel on desktop.
    if (!isDesktop) return
    const isPeekabooHidden = isLoggedIn ? isPeekabooHiddenLoggedIn : isPeekabooHiddenLoggedOut
    setIsPeekabooHiddenFE(isPeekabooHidden)
    if (isPeekabooHidden) return

    // Show with a bit of an animation.
    setTimeout(() => {
      containerRef.current?.classList.remove(...classNameHidden.split(' '))
      containerRef.current?.classList.add(...classNameVisible.split(' '))
    }, SHOW_DELAY)
  }, [isPeekabooHiddenLoggedOut, isPeekabooHiddenLoggedIn, isLoggedIn, isDesktop])

  const hideMe = useCallback(() => {
    containerRef.current?.classList.remove(...classNameVisible.split(' '))
    containerRef.current?.classList.add(...classNameHidden.split(' '))
    if (isLoggedIn) {
      setIsPeekabooHiddenLoggedIn(true)
    } else {
      setIsPeekabooHiddenLoggedOut(true)
    }
    trackClick('dismiss', { source: 'peekaboo_panel' })
  }, [setIsPeekabooHiddenLoggedOut, setIsPeekabooHiddenLoggedIn, isLoggedIn, trackClick])

  const { browserInfo } = useGlobalContext()
  const handleDownloadClick = useCallback(
    (store: 'google' | 'apple') => {
      trackClick('urlOpenExternal', { source: 'peekaboo_panel', store })
      // Add logic to open the appropriate app store link
      const storeUrl =
        store === 'google'
          ? appDownloadURLs[isLoggedIn ? 'loggedIn' : 'loggedOut'].google
          : appDownloadURLs[isLoggedIn ? 'loggedIn' : 'loggedOut'].apple
      const queryString = getDeeplinkQueryString(browserInfo, undefined)
      window.open(storeUrl + queryString, '_blank', 'noopener,noreferrer')
    },
    [trackClick, isLoggedIn, browserInfo]
  )

  if (PEEKABOO_EXEMPT_ROUTES.includes(pathname)) return null
  return (
    <div className="fixed bottom-6 right-6 z-50 flex items-end gap-6">
      {!isLoggedIn && <GSignInPanel />}
      {!isPeekabooHiddenFE && (
        <div
          className={[
            'group relative h-48 w-96 select-none transition-all duration-500 ease-in-out',
            classNameHidden,
          ].join(' ')}
          ref={containerRef}
        >
          <button
            className="btn btn-square absolute -right-4 -top-4 z-40 flex flex-wrap content-center justify-center bg-white text-black opacity-0 shadow-lg transition-opacity duration-500 group-hover:opacity-100"
            onClick={() => hideMe()}
          >
            <IconPlus className="size-5 rotate-45" />
          </button>
          <div className="flex size-full items-center space-y-2 rounded-3xl bg-white p-4 drop-shadow-md group-hover:drop-shadow-lg">
            <DownloadOptions
              className="size-full"
              onGooglePlayClick={() => handleDownloadClick('google')}
              onAppStoreClick={() => handleDownloadClick('apple')}
            />
          </div>
        </div>
      )}
    </div>
  )
}

import axios from 'axios'
import Long from 'long'
import { useCallback } from 'react'

import { useCurrentUserContext } from '@context/CurrentUserContext'
import { ShareMedium } from '@desktop/share/ExternalShareButtons'
import { commands } from '@lib/messages/protobuf'
import { IntoUrl } from '@models/IntoUrl'
import MixMix from '@services/MixMix'

import TrackExternalUrlPrivateShareEvent = commands.TrackExternalUrlPrivateShareEvent

export const useTrackExternalUrlShare = (url: IntoUrl, medium: ShareMedium) => {
  const { currentUserID } = useCurrentUserContext()
  return useCallback(() => {
    if (!currentUserID) return
    void axios
      .request(
        MixMix.commands.trackExternalUrlShare(
          new TrackExternalUrlPrivateShareEvent({
            senderUserId: currentUserID,
            urlId: Long.fromString(url.url_id),
            shareType: medium.shareType,
          })
        )
      )
      .catch(() => {})
  }, [currentUserID, url, medium])
}

/* eslint-disable @typescript-eslint/no-explicit-any */

import { commands } from '@lib/messages/protobuf'

export function encodeMessage(messageType: string, message: any): string {
  const MessageType = (commands as any)[messageType]
  if (!MessageType) {
    throw new Error(`Unknown message type: ${messageType}`)
  }
  const encodedMessage = MessageType.encode(message).finish()
  return Buffer.from(encodedMessage).toString('base64')
}

export function decodeMessage(messageType: string, encodedMessage: string): any {
  const MessageType = (commands as any)[messageType]
  if (!MessageType) {
    throw new Error(`Unknown message type: ${messageType}`)
  }
  const buffer = Buffer.from(encodedMessage, 'base64')
  return MessageType.decode(buffer)
}

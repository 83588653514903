import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

import { setReferrerContext } from '@redux/slices/analyticsSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'

export const ReferrerContextObserver = () => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { currentContext } = useAppSelector(state => state.analytics)
  const currentContextRef = useRef(currentContext)

  useEffect(() => {
    return () => {
      dispatch(setReferrerContext(currentContextRef.current))
      currentContextRef.current = currentContext
    }
  }, [dispatch, router.asPath, currentContext])

  return <></>
}

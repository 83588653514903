globalThis["__sentryRewritesTunnelPath__"] = "/mix_monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"10.4.7"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

import runtimeConfig from './src/lib/RuntimeConfig'

Sentry.init({
  environment: runtimeConfig().publicRuntimeConfig.appEnv,
  dsn: 'https://183dc781a52749ccba4dcf5a45346fac@o4505347962175488.ingest.us.sentry.io/4505347963486208',
  enabled: runtimeConfig().publicRuntimeConfig.appEnv === 'prd',

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  // Disable replay except for errors.
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0,

  ignoreErrors: [
    // Unactionable, coming from some analytics-related internals.
    /.*Non-Error promise rejection captured with value: Timeout.*/,
    // Unactionable, coming from the internals of Google Sign-In.
    /^W$/,
  ],

  integrations: [
    Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }),
    Sentry.rewriteFramesIntegration({ root: '/_next' }),
    Sentry.breadcrumbsIntegration({ console: true, dom: true, xhr: true, history: true, fetch: true }),
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.browserApiErrorsIntegration(),
  ],
})

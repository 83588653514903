import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import omit from 'lodash.omit'
import useSWR, { Arguments, SWRConfiguration, SWRResponse } from 'swr'
import useSWRInfinite, { SWRInfiniteConfiguration, SWRInfiniteResponse } from 'swr/infinite'

//#region Global Types
export type APIRequest<_Response = unknown, Request = unknown> = AxiosRequestConfig<Request>
//#endregion

//#region useRequest
interface Return<Data, Error> extends Omit<SWRResponse<AxiosResponse<Data>, AxiosError<Error>>, 'data'> {
  data: Data | undefined
  response: AxiosResponse<Data> | undefined
}

export interface Config<Data = unknown, Error = unknown>
  extends Omit<SWRConfiguration<AxiosResponse<Data>, AxiosError<Error>>, 'fallbackData'> {
  fallbackData?: Data
}

const apiRequestToCacheKey = (request: APIRequest) =>
  JSON.stringify({
    url: request.url,
    method: request.method?.toLowerCase(),
    data: omit(request, 'data.context').data,
    params: request.params,
  })

export function useRequest<Data = undefined, Error = unknown>(
  request: APIRequest<Data> | (() => APIRequest<Data> | null) | null,
  { fallbackData, ...config }: Config<Data, Error> = {}
): Return<Data, Error> {
  const { data, ...resp } = useSWR<AxiosResponse<Data>, AxiosError<Error>>(
    request
      ? typeof request === 'function'
        ? () => {
            const req = request()
            return req ? apiRequestToCacheKey(req) : null
          }
        : apiRequestToCacheKey(request)
      : null,
    request
      ? typeof request === 'function'
        ? // If the request function returns null, we are falling back to empty config
          // since the fetcher should not be called by useSWR when `getKey` function returns a null or throws.
          // https://swr.vercel.app/docs/conditional-fetching#conditional
          () => axios.request<Data>(request() ?? {})
        : () => axios.request<Data>(request)
      : null,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      fallbackData: fallbackData && {
        status: 200,
        statusText: 'InitialData',
        config: request as InternalAxiosRequestConfig<Data>,
        headers: {},
        data: fallbackData,
      },
      ...config,
    }
  )

  return {
    data: data?.data,
    response: data,
    ...resp,
  }
}

export function useModifiedRequest<Data, Error, RequestParams extends Arguments>(
  key: RequestParams,
  request: (params: RequestParams) => APIRequest<Data>,
  { fallbackData, ...config }: Config<Data, Error> = {}
): Return<Data, Error> {
  const { data, ...resp } = useSWR<AxiosResponse<Data>, AxiosError<Error>>(
    key,
    (params: RequestParams) => axios.request<Data>(request(params)),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      fallbackData: fallbackData && {
        status: 200,
        statusText: 'InitialData',
        config: request(key) as InternalAxiosRequestConfig<Data>,
        headers: {},
        data: fallbackData,
      },
      ...config,
    }
  )

  return {
    data: data?.data,
    response: data,
    ...resp,
  }
}

//#endregion

//#region useRequestInfinite

export interface ConfigInfinite<Data = unknown, Error = unknown>
  extends Omit<SWRInfiniteConfiguration<AxiosResponse<Data>, AxiosError<Error>>, 'fallbackData'> {
  fallbackData?: Data
}

export interface ReturnInfinite<Data, Error>
  extends Omit<SWRInfiniteResponse<AxiosResponse<Data>, AxiosError<Error>>, 'data'> {
  data: Data[] | undefined
  response: AxiosResponse<Data>[] | undefined
}

export function useRequestInfinite<Data, Error, RequestParams extends Arguments>(
  getKey: (index: number, previousPageData: Data) => RequestParams,
  request: (params: RequestParams) => APIRequest<Data>,
  { fallbackData, ...config }: ConfigInfinite<Data, Error> = {}
): ReturnInfinite<Data, Error> {
  const { data, ...resp } = useSWRInfinite<AxiosResponse<Data>, AxiosError<Error>>(getKey, {
    initialSize: 1,
    revalidateAll: false,
    persistSize: false,
    revalidateFirstPage: false,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    fallbackData: fallbackData
      ? [
          {
            status: 200,
            statusText: 'InitialData',
            config: request(getKey(0, fallbackData)) as InternalAxiosRequestConfig<Data>,
            headers: {},
            data: fallbackData,
          },
        ]
      : undefined,
    fetcher: (params: RequestParams) => axios.request<Data>(request(params)),
    ...config,
  })

  return {
    data: data?.map(page => page.data),
    response: data,
    ...resp,
  }
}

//#endregion

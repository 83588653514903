/* eslint-disable no-magic-numbers */

import Bowser from 'bowser'
import browserslistUA from 'browserslist-useragent'
import merge from 'lodash.merge'
import toSafeInteger from 'lodash.tostring'

// Bot-detection Regular Expressions
const SEARCH_BOTS_REGEXP = /(google|bing|yandex|duckduck|apple|amazon)bot|slurp/i
const GOOD_BOTS_REGEXP = /(twitter|slack|pinterest)bot|facebookexternalhit|bingpreview/i
const SPIDER_BOTS = /linespider|yeti|SemrushBot|MJ12bot/i

enum BrowserGrade {
  modern = 'modern',
  legacy = 'legacy',
  unsupported = 'unsupported',
}

export interface BrowserInfo {
  browser: {
    // Browser device types
    ios: boolean
    android: boolean
    windowsphone: boolean
    symbian: boolean
    electron: boolean
    // Browser names
    msie: boolean
    msedge: boolean
    chrome: boolean
    chromium: boolean
    firefox: boolean
    opera: boolean
    safari: boolean
    // Browser Rendering engines
    webkit: boolean
    gecko: boolean
    blink: boolean
    // Browser platform types
    bot: boolean
    searchBot: boolean
    mobile: boolean
    tablet: boolean
    // Browser capabilities
    canRedirect: boolean
    sameSite: boolean
    sameSiteNoneBug: boolean
    native: boolean
    // Browser grades
    modern: boolean
    legacy: boolean
    unsupported: boolean
    grade: BrowserGrade
  }
  // Other flags
  isDesktop: boolean
  isMobile: boolean
  isTablet: boolean
  ios: boolean
  android: boolean
  isWeb: boolean
  isInto: boolean
}

export const getBrowserInfo = (userAgent: string): BrowserInfo => {
  /**
   * `bowser` 2.x made massive breaking changes to the API,
   * but we are pretty dependent on the shape of the data from 1.x
   * @see https://github.com/lancedikson/bowser/blob/v1.x/README.md
   */
  const parsedUserAgent = parseUserAgent(userAgent)
  const { browser, engine, os, platform } = parsedUserAgent
  let browserFlags = {
    // Browser device types
    ios: os.name === 'iOS',
    android: os.name === 'Android',
    windowsphone: os.name === 'Windows Phone',
    symbian: false, // @see {parseSymbianClient}
    electron: false, // @see {parseElectronClient}
    // Browser names
    msie: browser.name === 'Internet Explorer',
    msedge: browser.name === 'Microsoft Edge',
    chrome: browser.name === 'Chrome',
    chromium: browser.name === 'Chromium',
    firefox: browser.name === 'Firefox',
    opera: browser.name === 'Opera',
    safari: browser.name === 'Safari',
    // Browser Rendering engines
    webkit: engine.name === 'WebKit',
    gecko: engine.name === 'Gecko',
    blink: engine.name === 'Blink',
    // Browser platform types
    bot: isKnownBot(userAgent),
    searchBot: isSearchBot(userAgent),
    mobile: platform.type === 'mobile',
    tablet: platform.type === 'tablet',
    // Browser capabilities
    canRedirect: true,
    sameSite: isSameSiteSupported(userAgent, parsedUserAgent),
    sameSiteNoneBug: isSameSiteNoneIncompatible(userAgent, parsedUserAgent),
    native: false,
    // Browser grades
    modern: false,
    legacy: false,
    unsupported: false,
    // Into clients
    isInto: false,
  }

  // Special clients
  const iosClient = parseiOSClient(userAgent) // Legacy iOS client
  const intoIosClient = parseIntoIosClient(userAgent) // "Into" iOS Client
  const androidClient = parseAndroidClient(userAgent)
  if (androidClient) {
    browserFlags = merge(browserFlags, androidClient.browserFlags)
  } else if (intoIosClient) {
    browserFlags = merge(browserFlags, intoIosClient.browserFlags)
  } else if (iosClient) {
    browserFlags = merge(browserFlags, iosClient.browserFlags)
  }

  // Web browser compatibility grade
  const browserGrade = parseBrowserGrade(userAgent, parsedUserAgent)

  return {
    browser: {
      ...browser,
      ...browserFlags,
      [browserGrade]: true,
      grade: browserGrade,
    },
    // Platform types
    isDesktop: !browserFlags.mobile && !browserFlags.tablet,
    isMobile: browserFlags.mobile,
    isTablet: browserFlags.tablet,
    // App types
    android: browserFlags.android,
    ios: browserFlags.ios,
    isWeb: isRecognizedWebBrowser(browserFlags),
    isInto: browserFlags.isInto,
  }
}

function parseUserAgent(userAgent: string): Bowser.Parser.ParsedResult {
  // Wrap bowser 2.x to gracefully handle bad user-agents
  let parsed = {
    browser: { name: 'Unknown', version: 'unknown' },
    engine: {},
    os: {},
    platform: {},
  }
  try {
    parsed = merge(parsed, Bowser.parse(userAgent))
  } catch (_e) {
    // Ignore parse errors
  }
  return parsed
}

function isRecognizedWebBrowser(browserFlags: Record<string, boolean>) {
  if (browserFlags.electron) {
    return true
  }
  if (browserFlags.native) {
    return false
  }
  for (const flag of [
    'msie',
    'msedge',
    'chrome',
    'chromium',
    'firefox',
    'safari',
    'opera',
    'webkit',
    'gecko',
    'blink',
    'symbian',
    'android',
  ]) {
    if (browserFlags[flag]) {
      return true
    }
  }
  return false
}

function isKnownBot(userAgent: string): boolean {
  return isSearchBot(userAgent) || GOOD_BOTS_REGEXP.test(userAgent) || SPIDER_BOTS.test(userAgent)
}

function isSearchBot(userAgent: string): boolean {
  return SEARCH_BOTS_REGEXP.test(userAgent)
}

function isModernUserAgent(userAgent: string): boolean {
  return browserslistUA.matchesUA(userAgent, {
    env: 'modern',
    allowHigherVersions: true,
  })
}

function isLegacyUserAgent(userAgent: string, parsedUserAgent: Bowser.Parser.ParsedResult): boolean {
  /**
   * Work-around `browserslist` not detecting Safari Extension WebViews
   * @see https://app.asana.com/0/226524042134185/1161945535761401/f
   * @see https://en.wikipedia.org/wiki/Safari_version_history#Safari_10_2
   */
  const SAFARI_10_WEBKIT_MAJOR_VERSION = 602
  const { engine } = parsedUserAgent
  if (engine.name === 'WebKit') {
    const [majorVersion] = splitVersion(engine.version ?? '')
    return parseInt(`${majorVersion}`, 10) >= SAFARI_10_WEBKIT_MAJOR_VERSION
  }
  return browserslistUA.matchesUA(userAgent, {
    env: 'legacy',
    allowHigherVersions: true,
  })
}

function parseBrowserGrade(userAgent: string, parsedUserAgent: Bowser.Parser.ParsedResult): BrowserGrade {
  try {
    if (isModernUserAgent(userAgent)) {
      return BrowserGrade.modern
    }
    if (isLegacyUserAgent(userAgent, parsedUserAgent)) {
      return BrowserGrade.legacy
    }
  } catch (_e) {
    // Ignore user-agent parsing errors
  }
  return BrowserGrade.unsupported
}

/**
 * Any browser that supports SameSite does not need to use CSRF tokens
 * @see https://app.asana.com/0/102902769366318/1161653358995225/f
 */
function isSameSiteSupported(userAgent: string, parsedUserAgent: Bowser.Parser.ParsedResult): boolean {
  const { browser, engine, os } = parsedUserAgent
  /**
   * SameSite is not supported on macOS prior to Mojave
   */
  if (os.name === 'macOS' && engine.name === 'WebKit') {
    const [majorOsVersion, minorOsVersion] = splitVersion(os.version ?? '')
    if (majorOsVersion === 10) {
      return parseInt(`${minorOsVersion}`, 10) >= 14
    }
  }
  /**
   * SameSite is broken for Firefox extensions in old versions
   */
  if (browser.name === 'Firefox') {
    const [majorBrowserVersion] = splitVersion(browser.version ?? '')
    return parseInt(`${majorBrowserVersion}`, 10) >= 74
  }
  /**
   * SameSite is broken for Chrome/Edge extensions
   * @see https://bugs.chromium.org/p/chromium/issues/detail?id=1020012
   */
  if (engine.name === 'Blink') {
    return false
  }
  try {
    return !browserslistUA.matchesUA(userAgent, { env: 'samesite-not-supported' })
  } catch (_e) {
    return false
  }
}

/**
 * Some older browsers don't follow the SameSite spec and have incompatible behavior
 * @see https://www.chromium.org/updates/same-site/incompatible-clients
 */
function isSameSiteNoneIncompatible(userAgent: string, parsedUserAgent: Bowser.Parser.ParsedResult): boolean {
  const { browser, engine, os } = parsedUserAgent
  if (os.name === 'macOS' && engine.name === 'WebKit') {
    const [majorOsVersion, minorOsVersion] = splitVersion(os.version ?? '')
    return majorOsVersion === 10 && minorOsVersion === 14 // Broken in Mojave
  }
  if (os.name === 'iOS') {
    const [majorOsVersion] = splitVersion(os.version ?? '')
    return majorOsVersion === 12
  }
  if (browser.name === 'Chrome' || browser.name === 'Chromium') {
    const [majorBrowserVersion] = splitVersion(browser.version ?? '')
    return parseInt(`${majorBrowserVersion}`, 10) >= 51 && parseInt(`${majorBrowserVersion}`, 10) <= 66
  }
  const ucBrowserMatch = /UCBrowser\/(\d+\.\d+\.\d+)[.\d]* /.exec(userAgent)
  if (ucBrowserMatch) {
    const [majorVersion, minorVersion, patchVersion] = splitVersion(ucBrowserMatch[1])
    if (majorVersion !== 12) {
      return parseInt(`${majorVersion}`, 10) < 12
    }
    if (minorVersion !== 13) {
      return parseInt(`${minorVersion}`, 10) < 13
    }
    return parseInt(`${patchVersion}`, 10) < 2
  }
  return false
}

interface ParsedAndroidClient {
  appFlavor: string | undefined
  appVersion: string
  browserFlags: {
    android: boolean
    mobile: boolean
    native: boolean
    canRedirect: boolean
    sameSite: boolean
    isInto: boolean
  }
}

function parseAndroidClient(userAgent: string): ParsedAndroidClient | undefined {
  // com.mix.android/1.0
  // com.mix.android.web/1.0
  // com.mix.android.tv/1.0
  const match = /^com\.mix\.android(\.([^/]+))?\/(\S+)/i.exec(userAgent)
  if (match) {
    // com.mix.android.web/404 → ['web', '404']
    // com.mix.android/404 → [undefined, '404']
    // com.mix.android.into/404 → ['into', '404']
    // com.mix.android.testio.into/404 → ['testio.into', '404']
    // com.mix.android.debug.into/404 → ['debug.into', '404']
    const [appFlavor, appVersion] = match.slice(-2) as [string | undefined, string | undefined]
    return {
      browserFlags: {
        mobile: true,
        android: true,
        native: true,
        canRedirect: userAgent.includes('Mozilla'),
        sameSite: false,
        isInto: appFlavor?.endsWith('into') === true,
      },
      appFlavor,
      appVersion: appVersion ?? '',
    }
  }
}

interface ParsediOSClient {
  appName: string
  appVariant?: string
  appVersion?: string
  buildNumber: string
  browserFlags: {
    ios: boolean
    mobile: boolean
    native: boolean
    canRedirect: boolean
    sameSite: boolean
    isInto: boolean
  }
}

function parseiOSClient(userAgent: string): ParsediOSClient | undefined {
  // Mix/186 CFNetwork/808.0.2 Darwin/16.0.0
  // MixShareExtension/186 CFNetwork/808.0.2 Darwin/16.0.0
  const cfNetworkMatch = /^([^/]+)\/(\S+)\sCFNetwork\/(\S+)\sDarwin\/(\S+)$/i.exec(userAgent)
  if (cfNetworkMatch) {
    const browserFlags = {
      mobile: true,
      ios: true,
      native: false,
      canRedirect: false,
      sameSite: false,
      isInto: false,
    }
    const appBuildMatch = /^(Mix[^/]*)\/(\S+)/i.exec(userAgent)
    if (appBuildMatch) {
      return {
        browserFlags: {
          ...browserFlags,
          native: true,
        },
        appName: appBuildMatch[1],
        buildNumber: appBuildMatch[2],
      }
    }
    return { browserFlags, appName: 'UnknownApp', buildNumber: '0' }
  }
}

function parseIntoIosClient(userAgent: string): ParsediOSClient | undefined {
  // Example user agents:
  // Mix/1.0 (com.mixmedia.mix.appstore; build:79; iOS 14.3.0) Alamofire/5.4.0
  // MixShareExtension/1.0 (com.mixmedia.mix.appstore.share; build:79; iOS 14.3.0) Alamofire/5.4.0
  const mixAppMatch =
    /^([^/]+)\/(\S+)\s\(com\.mixmedia\.mix\.appstore.?(\w+)?;\sbuild:(\d+);\siOS\s\S+\)\sAlamofire\/\S+$/i.exec(
      userAgent
    )
  if (mixAppMatch) {
    return {
      browserFlags: {
        mobile: true,
        ios: true,
        native: true,
        canRedirect: false,
        sameSite: false,
        isInto: true,
      },
      appName: mixAppMatch[1], // Example: "Mix", "Mix Share Extension"
      appVersion: mixAppMatch[2], // Example: "1.0"
      appVariant: mixAppMatch[3], // Example: undefined, "share"
      buildNumber: mixAppMatch[4], // Example: 79
    }
  }
}

function splitVersion(version: string): (string | number)[] {
  if (!version) {
    return []
  }
  return `${version}`.split('.').map(part => (isNaN(+part) ? part : toSafeInteger(part)))
}

import { useEffect } from 'react'

import { useSegmentTrack } from './useSegmentAnalytics'

export const useTrackViewError = ({ disabled }: { disabled: boolean }) => {
  const track = useSegmentTrack()

  useEffect(() => {
    if (disabled) return
    track('viewError')
  }, [track, disabled])
}

// @ts-check
'use strict'

import pino from 'pino'

import { isBrowser } from './runtime-environment'
import runtimeConfig from './RuntimeConfig'

let level = 'debug'
// 🤫in browser
if (isBrowser()) {
  switch (runtimeConfig().publicRuntimeConfig.appEnv) {
    case 'prd':
      level = 'error'
      break
    case 'stg':
    case 'canary':
    case 'dev':
      level = 'debug'
      break
  }
}

const logger: pino.Logger = pino({
  errorKey: 'error',
  messageKey: 'message',
  level,
  redact: ['token', 'secret'],
  serializers: {
    error: pino.stdSerializers.err,
  },
})
export default logger

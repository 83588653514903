import React, { ForwardedRef, forwardRef, ReactElement } from 'react'

import { useIsDesktop } from '@hooks/useIsDesktop'
import { selectIsCanary } from '@redux/slices/tweaksSlice'
import { useAppSelector } from '@redux/store/store'

interface HeaderProps {
  children: ReactElement[]
  className: string
  backgroundClassNameOverride?: string
}

const HeaderInner = (
  { children, className, backgroundClassNameOverride }: HeaderProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const isCanary = useAppSelector(selectIsCanary)
  const isDesktop = useIsDesktop()

  const backgroundClassName = (() => {
    // Don't respect the background classname override in mobile and tablet.
    if (!isDesktop) return ''
    // Use the override if we are given one
    if (backgroundClassNameOverride) return backgroundClassNameOverride
    // Fallback to the appropriate background color
    return isCanary ? 'bg-accent/[.1]' : 'bg-toolbar'
  })()

  return (
    <div
      className={`sticky top-0 z-50 w-full shrink-0 ${
        backgroundClassNameOverride || !isDesktop ? '' : 'border-b'
      } border-primary/5 bg-toolbar`}
      ref={ref}
    >
      <div className={`flex items-center justify-between py-3 ${backgroundClassName} ${className}`}>{children}</div>
    </div>
  )
}

const Header = forwardRef(HeaderInner)
export default Header

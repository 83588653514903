import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useRef, useState } from 'react'

import { useTheme } from '@context/ThemeContext'
import { ContextMenu } from '@desktop/ContextMenu'
import LoginButton from '@desktop/login/LoginButton'
import { ToolbarActions } from '@desktop/toolbar/ToolbarUrlActions'
import IconMenuHamburger from '@icons/IconMenuHamburger.svg'
import FilterButton from '@mobile/FilterButton'
import { useAppSelector } from '@redux/store/store'

const LoggedOutHeaderLogo = () => (
  <div className="flex translate-y-2">
    <Link href="/" className="btn btn-link">
      <div className="relative h-8 w-24">
        <Image
          src={useTheme().resolvedTheme === 'dark' ? '/logo_white.svg' : '/logo_black.svg'}
          alt="Mix"
          priority={true}
          fill={true}
        />
      </div>
    </Link>
  </div>
)

const LoggedOutHeaderMenu = () => {
  const [showMobileContextMenu, setShowMobileContextMenu] = useState(false)

  const menuRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: TouchEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowMobileContextMenu(false)
      }
    }
    document.addEventListener('touchstart', handleClickOutside)

    return () => {
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [])

  return (
    <div ref={menuRef} className="relative inline-block">
      <div
        slot="trigger"
        className={`group cursor-pointer rounded-full p-2 sm:hover:bg-primary/90 sm:hover:before:fixed sm:hover:before:right-0 sm:hover:before:top-0 sm:hover:before:z-10 sm:hover:before:block sm:hover:before:h-48 sm:hover:before:w-20 ${showMobileContextMenu ? 'bg-primary/90' : ''} `}
      >
        <ContextMenu
          position="topRight"
          isVisible={showMobileContextMenu}
          toggleVisibility={setShowMobileContextMenu}
        />
        <div
          className="relative"
          onTouchStart={e => {
            e.stopPropagation()
            setShowMobileContextMenu(prev => !prev)
          }}
        >
          <div className="absolute inset-0 m-[-15px] opacity-0"></div>
          <div
            className={`aspect-square w-6 text-primary transition-opacity duration-200 sm:hover:opacity-100 group-hover:sm:opacity-100 ${showMobileContextMenu ? 'opacity-100' : 'opacity-50'} `}
          >
            <IconMenuHamburger />
          </div>
        </div>
      </div>
    </div>
  )
}

const Header = () => {
  const { isTitleEnabled } = useAppSelector(state => state.header)
  return (
    <div className={`sticky top-0 z-50 w-full shrink-0 bg-gradient-to-b from-black to-transparent`}>
      <div className="flex items-center justify-between p-3">
        <div className="flex w-full items-center space-x-2">
          {isTitleEnabled ? <FilterButton /> : <LoggedOutHeaderLogo />}
        </div>
        <ToolbarActions />
        <div className="flex w-full items-center justify-end space-x-4">
          <LoginButton className="btn bg-white/15 py-2 text-sm font-semibold">Get the app</LoginButton>
          <LoggedOutHeaderMenu />
        </div>
      </div>
    </div>
  )
}

export default Header

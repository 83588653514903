import React, { MouseEventHandler } from 'react'

import { useCurrentUserContext } from '@context/CurrentUserContext'
import { useTheme } from '@context/ThemeContext'
import LoginButton from '@desktop/login/LoginButton'
import IconChecked from '@icons/IconChecked.svg'
import IconPlus from '@icons/IconPlus.svg'

export const FollowButtonIcon = ({
  isFollowing,
  onFollow,
  deeplinkUrl,
  className,
}: {
  isFollowing: boolean
  onFollow: MouseEventHandler<HTMLButtonElement>
  deeplinkUrl?: string
  className?: string
}) => (
  <LoginButton onClickIfLoggedIn={onFollow} deeplinkUrl={deeplinkUrl}>
    {isFollowing ? (
      <IconChecked className={`size-full ${className}`} />
    ) : (
      <IconPlus className={`size-full ${className}`} />
    )}
  </LoginButton>
)

export const FollowButtonSmall = ({
  isFollowing,
  onFollow,
  deeplinkUrl,
}: {
  isFollowing: boolean
  onFollow: () => void
  deeplinkUrl?: string
}) => (
  <LoginButton
    className={['btn btn-square', isFollowing ? 'btn-secondary' : ''].join(' ')}
    onClickIfLoggedIn={onFollow}
    deeplinkUrl={deeplinkUrl}
  >
    {isFollowing ? <IconChecked className="size-5" /> : <IconPlus className="size-5" />}
  </LoginButton>
)

export const FollowButton = ({
  isFollowing,
  onFollow,
  className,
}: {
  isFollowing: boolean
  onFollow: () => void
  className: string
}) => {
  const theme = useTheme()
  const { isLoggedIn } = useCurrentUserContext()
  const followingClassNames = theme.resolvedTheme === 'dark' ? 'bg-accent-dark text-white' : 'bg-accent-dark text-white'
  const notFollowingClassNames =
    theme.resolvedTheme === 'dark' ? 'bg-contrast/30 text-white/70' : 'bg-contrast/10 text-primary'

  return (
    <LoginButton
      className={`btn btn-primary font-semibold ${isFollowing ? followingClassNames : notFollowingClassNames} ${!isLoggedIn ? 'btn-dot' : ''} ${className}`}
      onClickIfLoggedIn={onFollow}
    >
      {isFollowing ? 'Following' : 'Follow'}
    </LoginButton>
  )
}

import React from 'react'

import { MetaTags } from '@desktop/MetaTags'
import UrlHelper from '@lib/UrlHelper'
import { IntoUser } from '@models/IntoUser'

export const UserProfileMetaTags = ({ user }: { user: IntoUser }) => (
  <MetaTags
    title={`${user.display_name} (@${user.username}) • Mix`}
    description={user.bio}
    image={user.photo_url}
    permalink={UrlHelper.userPermalink(user.username)}
  />
)

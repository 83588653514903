import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Commands } from '@tracking/utils/tags'
import { AppState } from '../store/store'

export interface BusinessEvent {
  type: 'request' | 'response' | 'error'
  command: Commands
  url: string
  method: string
  data: object
  responseData?: object
  statusCode?: number
  timestamp: number
  error?: string
}

interface BusinessEventsState {
  events: BusinessEvent[]
}

const initialState: BusinessEventsState = {
  events: [],
}

const businessEventsSlice = createSlice({
  name: 'businessEvents',
  initialState,
  reducers: {
    addBusinessEvent: (state, action: PayloadAction<BusinessEvent>) => {
      state.events.push(action.payload)
    },
    clearBusinessEvents: state => {
      state.events = []
    },
  },
})

export const { addBusinessEvent, clearBusinessEvents } = businessEventsSlice.actions

export const selectBusinessEvents = (state: AppState) => state.businessEvents.events

export default businessEventsSlice.reducer

import React, { FC, ReactNode, useEffect, useState } from 'react'

const SupportEmailLink: FC<{ subject: string; body?: string; className?: string; children: ReactNode }> = ({
  subject,
  body,
  className,
  children,
}) => {
  const [bodyFilled, setBodyFilled] = useState(body)

  useEffect(() => {
    setBodyFilled(bodyFilled ?? `Page: ${window.location.href}`)
  }, [bodyFilled])

  const encodedSubject = encodeURIComponent(subject)
  const encodedBody = encodeURIComponent(bodyFilled ?? '')
  return (
    <>
      <a className={className} href={`mailto:support@mix.com?subject=${encodedSubject}&body=${encodedBody}`}>
        {children}
      </a>
    </>
  )
}

export default SupportEmailLink

import { keyForResource } from '@models/APIResource'
import RecommendResponse, { filterResourcesByType } from '@models/RecommendResponse'
import feedApi from '../api/feedApi'
import { feedConstants, selectCurrentFeed } from '../slices/feedSlice'
import { AppDispatch, AppState } from '../store/store'

export function isDefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined
}

export async function injectUrlsIntoFeed(dispatch: AppDispatch, state: AppState, context: RecommendResponse) {
  const { meta, items: exploitResources } = context
  const insertAfter = meta?.insertAfter
  const exploitUrls = filterResourcesByType(exploitResources, 'url')

  if (exploitUrls?.length > 0 && insertAfter) {
    const { mediaTypes } = state.app
    const { _instanceId, identifier } = state.feed
    const currentFeed = selectCurrentFeed(state)
    const { currentFeedItemKey } = state.feed
    const currentIndex = currentFeed?.items.findIndex(item => {
      const key = keyForResource(item)
      return key === currentFeedItemKey
    })

    const filterIds = [...(currentFeed?.items ?? [])]
      .map(item => {
        if (item.type === 'url') {
          return item.url_id
        }
      })
      .filter(isDefined)

    const feedArgs = {
      page: 0,
      pageSize: feedConstants.FULL_SCREEN_PAGE_SIZE,
      contextId: currentFeedItemKey,
      slug: identifier.slug,
      mediaTypes: Array.from(mediaTypes),
      filterIds,
      isFollowable: identifier.isFollowable,
      _instanceId,
    }
    if (currentIndex !== undefined && currentIndex !== -1 && !filterIds.includes(exploitUrls[0].url_id)) {
      dispatch(
        feedApi.util.updateQueryData('getFeedData', feedArgs, draft => {
          draft?.items.splice(currentIndex + insertAfter, 0, ...exploitUrls)
        })
      )
    }
  }
}

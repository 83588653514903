import dynamic from 'next/dynamic'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Rnd, RndResizeCallback } from 'react-rnd'

import { useLocalStorageImmer } from '@hooks/useLocalStorageImmer'
import IconPlus from '@icons/IconPlus.svg'
import { BusinessEvent, clearBusinessEvents, selectBusinessEvents } from '@redux/slices/businessEventsSlice'
import { selectShowBusinessEventsModal, setTweaksState } from '@redux/slices/tweaksSlice'
import DynamicReactJsonView from './DynamicReactJsonView'

const COLLAPSED_HEIGHT = 64
const HEADER_HEIGHT = 150

const BusinessEventsModal: React.FC = () => {
  const dispatch = useDispatch()
  const events = useSelector(selectBusinessEvents)
  const showModal = useSelector(selectShowBusinessEventsModal)
  const [filter, setFilter] = useState('')

  const [{ size, position, isCollapsed }, setModalState] = useLocalStorageImmer('businessEventsModalState', {
    size: { width: 600, height: 400 },
    position: { x: 20, y: 20 },
    isCollapsed: false,
  })

  const filteredEvents = events.filter((event: BusinessEvent) =>
    event.command.toLowerCase().includes(filter.toLowerCase())
  )

  const handleClose = useCallback(() => {
    dispatch(setTweaksState({ key: 'showBusinessEventsModal', isChecked: false }))
  }, [dispatch])

  const handleClear = useCallback(() => {
    dispatch(clearBusinessEvents())
  }, [dispatch])

  const toggleCollapse = useCallback(() => {
    setModalState(state => {
      state.isCollapsed = !state.isCollapsed
    })
  }, [setModalState])

  const handleResizeStop = useCallback<RndResizeCallback>(
    (_e, _direction, ref, _delta, position) => {
      setModalState(state => {
        state.size = { width: parseInt(ref.style.width), height: parseInt(ref.style.height) }
        state.position = position
      })
    },
    [setModalState]
  )

  if (!showModal) return null

  return (
    <Rnd
      size={{ width: size.width, height: isCollapsed ? COLLAPSED_HEIGHT : size.height }}
      position={{ x: position.x, y: position.y }}
      onDragStop={(e, d) =>
        setModalState(state => {
          state.position = { x: d.x, y: d.y }
        })
      }
      onResizeStop={handleResizeStop}
      className="z-50 overflow-hidden rounded-lg bg-gray-700 font-mono text-white shadow-lg"
      dragHandleClassName="handle"
    >
      <div className="handle flex cursor-move items-center justify-between bg-gray-600 p-4">
        <button onClick={toggleCollapse} className="px-2 font-sans text-xl font-light hover:text-gray-400">
          {isCollapsed ? '+' : '-'}
        </button>
        <h2 className="text-lg font-semibold">Business Events</h2>
        <button onClick={handleClose}>
          <IconPlus className="size-4 rotate-45 scale-125 text-white hover:text-gray-400" />
        </button>
      </div>
      {!isCollapsed && (
        <div className="relative flex flex-col gap-4 p-4 text-gray-50">
          <div className="flex items-center gap-2">
            <input
              type="text"
              placeholder="Filter events..."
              value={filter}
              onChange={e => setFilter(e.target.value)}
              className="w-full rounded border border-gray-300 p-2 text-stone-700"
            />
            <button onClick={handleClear} className="self-stretch rounded bg-gray-500 px-2 py-1">
              Clear
            </button>
          </div>
          <div
            className="flex flex-col gap-4 overflow-y-auto scrollbar-hide"
            style={{ height: size.height - HEADER_HEIGHT }}
          >
            {filteredEvents.map((event: BusinessEvent, index) => (
              <div key={index} className="flex flex-col gap-2 rounded bg-gray-500 p-2">
                <div className="flex justify-between">
                  <h3 className="text-sm">
                    {event.command}
                    {(event.data as unknown as { urlId?: string }).urlId ? (
                      <span className="text-xs"> [{(event.data as unknown as { urlId?: string }).urlId}]</span>
                    ) : null}
                  </h3>
                  <div className="text-gray-700">
                    {event.type === 'request' ? '⏳' : event.type === 'response' ? '✅' : '❌'} {event.statusCode}
                  </div>
                </div>
                {event.type === 'request' && event.data && (
                  <DynamicReactJsonView
                    src={event.data}
                    name={false}
                    displayDataTypes={false}
                    collapsed={0}
                    theme="tomorrow"
                  />
                )}
                {event.type === 'response' && event.responseData && (
                  <DynamicReactJsonView
                    src={event.responseData}
                    name={false}
                    displayDataTypes={false}
                    collapsed={1}
                    theme="tomorrow"
                  />
                )}
                {event.type === 'error' && event.error && (
                  <DynamicReactJsonView
                    src={JSON.parse(event.error)}
                    name={false}
                    displayDataTypes={false}
                    collapsed={1}
                    theme="tomorrow"
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </Rnd>
  )
}

// Avoid SSR because of localStorage.
export default dynamic(() => Promise.resolve(BusinessEventsModal), { ssr: false })

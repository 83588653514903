import toast from 'react-hot-toast'

import logger from '@lib/logger'
import { WEEK_TO_SECONDS } from '@lib/time-constants'
import { NotificationsResponse } from '@models/NotificationsResponse'
import IntoAPI from '@services/IntoAPI'
import { setHasReachedEnd } from '../slices/notificationsSlice'
import { mixApi } from './root'

interface NotificationsApiRequest {
  page: number
  cookie?: string
}

const notificationsApi = mixApi.injectEndpoints({
  endpoints: builder => ({
    getNotifications: builder.query<NotificationsResponse, NotificationsApiRequest>({
      keepUnusedDataFor: WEEK_TO_SECONDS,
      query: ({ page, cookie }) => {
        const config = IntoAPI.notifications.getNotifications(page)
        if (cookie) {
          config.headers = { ...config.headers, cookie }
        }
        return {
          config,
        }
      },
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled
          const { data } = response
          const { items } = data

          if (!items?.length) {
            dispatch(setHasReachedEnd(true))
          }
        } catch (_err) {
          /* Do nothing */
        }
      },
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge(currentCache, newState) {
        const existingNotifications = currentCache
        if (existingNotifications && newState) {
          existingNotifications.items = [...existingNotifications.items, ...newState.items]
        } else {
          currentCache = newState
        }

        return currentCache
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page
      },
    }),
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    getUnreadCount: builder.query<{ activities: number; notifications: number; shares: number }, void>({
      keepUnusedDataFor: WEEK_TO_SECONDS,
      query: () => {
        return { config: IntoAPI.notifications.getUnreadNotificationCount() }
      },
      serializeQueryArgs: ({ endpointName }) => endpointName,
    }),
    markAllRead: builder.mutation({
      query: () => {
        return { config: IntoAPI.notifications.markAllNotificationsRead() }
      },
      onQueryStarted: (_args, { dispatch, queryFulfilled }) => {
        const countPatchResult = dispatch(
          notificationsApi.util.updateQueryData('getUnreadCount', undefined, draft => {
            if (draft) {
              draft.notifications = 0
            }
          })
        )

        const notificationPatchResult = dispatch(
          notificationsApi.util.updateQueryData('getNotifications', { page: 1 }, draft => {
            if (draft) {
              draft.items.map(notification => (notification.status = 'read'))
            }
          })
        )

        toast.success('Marked everything as read')

        queryFulfilled.catch(err => {
          logger.warn('Failed to mark notifications as read', err)
          toast.error('Failed to mark everything as read')
          countPatchResult.undo()
          notificationPatchResult.undo()
        })
      },
    }),
    markSingleRead: builder.mutation({
      query: notificationID => {
        return { config: IntoAPI.notifications.markNotificationRead(notificationID) }
      },
      onQueryStarted: (notificationID, { dispatch, queryFulfilled }) => {
        const countPatchResult = dispatch(
          notificationsApi.util.updateQueryData('getUnreadCount', undefined, draft => {
            if (draft) {
              draft.notifications--
            }
          })
        )

        const notificationPatchResult = dispatch(
          notificationsApi.util.updateQueryData('getNotifications', { page: 1 }, draft => {
            if (draft) {
              const notification = draft.items.find(item => (item.id = notificationID))
              if (notification) notification.status = 'read'
            }
          })
        )

        queryFulfilled.catch(() => {
          countPatchResult.undo()
          notificationPatchResult.undo()
        })
      },
    }),
  }),
})

export default notificationsApi

import { HYDRATE } from 'next-redux-wrapper'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from '../../store/store'

export interface InterstitialModalSlice {
  isInterstitialModalOpen: boolean
  _metadata: { updatedFields: string[] }
}

const initialState: InterstitialModalSlice = {
  isInterstitialModalOpen: false,
  _metadata: { updatedFields: [] },
}

const interstitialModalSlice = createSlice({
  name: 'interstitialModal',
  initialState,
  reducers: {
    setIsInterstitialModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isInterstitialModalOpen = action.payload
      state._metadata.updatedFields.push('isInterstitialModalOpen')
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      (action): action is PayloadAction<AppState> => action.type === HYDRATE,
      (state, action) => {
        const serverApp = action.payload.interstitialModal

        const fieldsToCheck: (keyof InterstitialModalSlice)[] = ['isInterstitialModalOpen']

        function updateField<K extends keyof InterstitialModalSlice>(
          state: InterstitialModalSlice,
          field: K,
          value: InterstitialModalSlice[K]
        ): void {
          state[field] = value
        }

        fieldsToCheck.forEach(field => {
          if (
            serverApp._metadata.updatedFields.includes(field) &&
            serverApp[field] !== undefined &&
            serverApp[field] !== state[field]
          ) {
            updateField(state, field, serverApp[field])
          }
        })
        state._metadata.updatedFields = []
      }
    )
  },
})

export const { setIsInterstitialModalOpen } = interstitialModalSlice.actions
export default interstitialModalSlice.reducer

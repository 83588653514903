import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useRef } from 'react'

import { useCommandContext } from '@context/CommandContext'
import { useGlobalContext } from '@context/GlobalContexts'
import { useSession } from '@context/SessionManagementContext'
import { useTheme } from '@context/ThemeContext'
import logger from '@lib/logger'
import runtimeConfig from '@lib/RuntimeConfig'
import { IntoUser } from '@models/IntoUser'
import { useAppSelector } from '@redux/store/store'
import { AnalyticsBrowser } from '@segment/analytics-next'

// Create a singleton instance of AnalyticsBrowser.
let analyticsBrowserInstance: AnalyticsBrowser | null = null

export const useSegmentTrack = () => {
  const { session } = useSession()
  const commandContext = useAppSelector(state => state.analytics)
  const analytics = useSegmentAnalytics()

  const commandContextRef = useRef(commandContext)
  useEffect(() => {
    commandContextRef.current = commandContext
  }, [commandContext])

  return useCallback(
    (eventName: string, properties?: object) => {
      const { currentContext, referrerContext } = commandContextRef.current
      if (!session?.id || !currentContext) return
      analytics
        ?.track(eventName, {
          // session
          session_id: session?.id,
          client_session_id: session?.id,
          // currentContext
          current_page_name: currentContext?.page,
          current_page_section: currentContext?.pageSection,
          current_stream_type: currentContext?.streamType,
          // referrerContext
          referrer_page_name: referrerContext?.page,
          referrer_page_section: referrerContext?.pageSection,
          referrer_stream_type: referrerContext?.streamType,
          ...properties,
        })
        .catch(err => {
          logger.error(`Error tracking event - ${eventName}`, { err, properties })
        })
    },
    [analytics, session?.id]
  )
}

export const useSegmentIdentify = ({
  currentUser,
  isLoggedIn,
}: {
  currentUser: IntoUser | undefined
  isLoggedIn: boolean
}) => {
  const analytics = useSegmentAnalytics()
  const {
    commandContext: { session },
  } = useCommandContext()
  const { resolvedTheme } = useTheme()
  const themePreference = useMemo(() => {
    if (!resolvedTheme) return undefined
    if (resolvedTheme === 'dark') return 'dark'
    if (resolvedTheme === 'light') return 'light'
    logger.error(`Unrecognized theme preference - ${resolvedTheme}`)
    return undefined
  }, [resolvedTheme])

  return useCallback(() => {
    try {
      if (!isLoggedIn || !currentUser || !currentUser.user_id) return
      void analytics?.identify(`${currentUser.user_id}`, {
        device_id: session?.device?.deviceUuid?.value,
        category: currentUser?.category,
        is_default_photo: currentUser?.meta.is_default_photo,
        display_name: currentUser?.display_name,
        is_default_username: currentUser?.meta.is_default_username,
        theme_preference: themePreference,
      })
    } catch (err) {
      logger.error('Error identifying segment user', { err })
    }
  }, [analytics, session?.device?.deviceUuid?.value, currentUser, isLoggedIn, themePreference])
}

export const useResetSegmentUser = () => {
  const analytics = useSegmentAnalytics()
  return useCallback(() => {
    try {
      void analytics?.reset()
    } catch (err) {
      logger.error('Error resetting segment user', { err })
    }
  }, [analytics])
}

const useSegmentAnalytics = () => {
  const router = useRouter()
  const { writeKey, cdnURL } = runtimeConfig().publicRuntimeConfig.analytics.segment
  const { browserInfo } = useGlobalContext()

  const shouldInitialize = useMemo(() => {
    return !browserInfo.browser.bot && router.route !== '/404'
  }, [browserInfo.browser.bot, router.route])

  useEffect(() => {
    if (shouldInitialize && !analyticsBrowserInstance) {
      analyticsBrowserInstance = AnalyticsBrowser.load({ writeKey, cdnURL })
    }
  }, [shouldInitialize, writeKey, cdnURL])

  return analyticsBrowserInstance
}

import React from 'react'
import { useInView } from 'react-intersection-observer'

import { NotificationsList } from '@common/NotificationsList'
import { HoverMenu } from '@desktop/HoverMenu'
import IconCheck from '@icons/IconChecked.svg'
import notificationsApi from '@redux/api/notificationsApi'

const NotificationsMenuHeader = ({ isLoading, isEmpty }: { isLoading: boolean; isEmpty: boolean }) => {
  const { data } = notificationsApi.useGetUnreadCountQuery()
  const [markAllRead] = notificationsApi.useMarkAllReadMutation()
  const isFullyRead = data?.notifications === 0

  return (
    <div className="sticky p-4 text-center font-bold tracking-wider light:bg-menu dark:bg-white/[.16]">
      <div className="relative flex justify-center">
        <div>Notifications</div>
        {!isLoading && !isEmpty && (
          <button
            disabled={isFullyRead}
            className=":not(disabled):hover:rounded-full :not(disabled):hover:text-white :not(disabled):hover:opacity-100 absolute right-0 size-6 shrink-0 opacity-50 transition-all duration-150 light:text-menu dark:text-primary"
            onClick={markAllRead}
          >
            <IconCheck />
          </button>
        )}
      </div>
    </div>
  )
}

export const NotificationsMenu = () => {
  const { ref } = useInView({
    triggerOnce: true,
  })

  const { data, isLoading } = notificationsApi.useGetNotificationsQuery({ page: 1 }, { skip: false })

  return (
    <HoverMenu position="topRight">
      <div ref={ref} className="relative overflow-hidden rounded-xl bg-primary light:bg-menu">
        <NotificationsMenuHeader isLoading={isLoading} isEmpty={data?.items?.length === 0} />
        <div className="scrollbar-menu max-h-96 min-w-96 overflow-x-hidden whitespace-break-spaces">
          <NotificationsList />
        </div>
      </div>
    </HoverMenu>
  )
}

import axios from 'axios'
import Link from 'next/link'
import React, { useCallback, useEffect } from 'react'

import Modal from '@common/Modal'
import useFeedNavigation from '@hooks/useFeedNavigation'
import { useIsDesktop } from '@hooks/useIsDesktop'
import useKeyboardShortcut from '@hooks/useKeyboardShortcut'
import logger from '@lib/logger'
import { ContestHeroVideo } from '@pages/contest/components/ContestHeroVideo'
import { CONTEST_CATEGORY } from '@pages/contest/submit.page'
import { selectCurrentItem } from '@redux/slices/feedSlice'
import { useSetHeaderState } from '@redux/slices/headerSlice'
import { setIsInterstitialModalOpen } from '@redux/slices/modals/interstitialModalSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'
import IntoAPI from '@services/IntoAPI'

const ContestJoinPrompt = () => {
  const isDesktop = useIsDesktop()
  return (
    <>
      <div className="flex w-full flex-col items-center space-y-6 sm:h-1/4">
        <Link
          className="btn btn-accent w-full text-[17px] font-medium sm:max-w-80"
          href={'/contest/breakdancing'}
          target="_blank"
        >
          Join the contest
        </Link>
        {!isDesktop && <div className="text-[14px] opacity-40">Swipe up to skip</div>}
      </div>
    </>
  )
}

const ContestText1 = () => (
  <div className="flex h-1/3 flex-col items-center justify-center space-y-6 text-[32px] font-bold leading-[110%] tracking-[-1.5px] sm:w-1/3 sm:space-y-4 sm:tracking-normal">
    <div className="flex space-x-1 rounded-lg bg-accent/10 px-3 py-1.5 text-[13px] font-medium leading-[120%] tracking-normal text-accent mt-safe-offset-8">
      <div>⭐</div>
      <div>Curation Contest</div>
    </div>
    <div className="flex flex-col items-center space-y-2">
      <h1>Submit great videos</h1>
      <h2 className="text-accent">Win $500*</h2>
    </div>
  </div>
)

const ContestText2 = () => {
  return (
    <div className="flex flex-col items-center sm:w-1/3 sm:space-y-1">
      <div className="text-[14px] font-medium tracking-[-1%] opacity-70 sm:text-[13px] sm:leading-[120%] sm:opacity-100">
        CONTEST TOPIC
      </div>
      <div className="flex text-[24px] font-bold leading-[110%] tracking-[-1px] text-white sm:text-[32px] sm:tracking-normal">
        {CONTEST_CATEGORY}
      </div>
    </div>
  )
}

export const CurationContestInterstitial = () => {
  const currentItem = useAppSelector(selectCurrentItem)
  const markAsRead = useCallback((notificationId: string) => {
    axios.request(IntoAPI.notifications.markNotificationRead(notificationId)).catch(err => {
      logger.warn('Failed to mark share as read', err)
    })
  }, [])

  useEffect(() => {
    if (currentItem?.type === 'web_interstitial' && currentItem.notification_id) markAsRead(currentItem.notification_id)
  }, [markAsRead, currentItem])

  return (
    <div className="relative h-full select-none overflow-hidden font-apps">
      <div className="size-full scale-125 sm:scale-100">
        <ContestHeroVideo />
      </div>
      <div className="absolute top-0 flex size-full flex-col justify-between px-6 py-4">
        <div className="hidden sm:block sm:h-1/5" />
        <div className="flex h-4/5 flex-col items-center justify-between sm:h-full sm:flex-row">
          <ContestText1 />
          <ContestText2 />
        </div>
        <ContestJoinPrompt />
      </div>
    </div>
  )
}

export const CurationContestInterstitialModal = () => {
  const dispatch = useAppDispatch()
  const { isInterstitialModalOpen } = useAppSelector(state => state.interstitialModal)
  const { handleNextNavigation, handlePreviousNavigation } = useFeedNavigation()

  const handleArrowUp = useCallback(() => {
    dispatch(setIsInterstitialModalOpen(false))
    handlePreviousNavigation()?.catch(() => {})
  }, [dispatch, handlePreviousNavigation])

  const handleArrowDown = useCallback(() => {
    dispatch(setIsInterstitialModalOpen(false))
    handleNextNavigation()?.catch(() => {})
  }, [dispatch, handleNextNavigation])

  useKeyboardShortcut(
    {
      codes: {
        ArrowUp: handleArrowUp,
        ArrowDown: handleArrowDown,
      },
    },
    { overrideModalRestrictions: true }
  )

  const onClose = async () => {
    dispatch(setIsInterstitialModalOpen(false))
    await handleNextNavigation()
  }

  return (
    <Modal isVisible={isInterstitialModalOpen} onClose={onClose}>
      <div
        onClick={onClose}
        className="absolute left-0 top-0 flex size-full select-none items-center justify-center font-apps"
      >
        <div className="relative size-3/4 overflow-hidden rounded-2xl">
          <CurationContestInterstitial />
        </div>
      </div>
    </Modal>
  )
}

const CurationContestPage = () => {
  useSetHeaderState({
    isUrlActionsEnabled: false,
    isGridActionsEnabled: false,
    isTitleEnabled: false,
    isSharingEnabled: false,
    isDisabled: true,
    isFiltersEnabled: false,
  })
  return (
    <div className="h-screen">
      <CurationContestInterstitial />
    </div>
  )
}

export default CurationContestPage

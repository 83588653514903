import React, { createContext, ReactNode, useContext, useState } from 'react'

import SidePanel from '@mobile/SidePanel'

const SidePanelContext = createContext<{
  openSidePanel: (content: ReactNode) => void
  closeSidePanel: () => void
}>({
  openSidePanel: () => {},
  closeSidePanel: () => {},
})

export const SidePanelProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [content, setContent] = useState<ReactNode | null>(null)

  const openSidePanel = (content: ReactNode) => {
    setContent(content)
    setIsOpen(true)
  }

  const closeSidePanel = () => {
    setIsOpen(false)
    setContent(null)
  }

  return (
    <SidePanelContext.Provider value={{ openSidePanel, closeSidePanel }}>
      {children}
      <SidePanel isOpen={isOpen} closePanel={closeSidePanel}>
        {content}
      </SidePanel>
    </SidePanelContext.Provider>
  )
}

export const useSidePanel = () => useContext(SidePanelContext)

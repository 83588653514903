import { AnimatePresence, motion, PanInfo } from 'framer-motion'
import { ReactNode, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

const CLOSE_THRESHOLD = 50

const BottomSheet = ({
  children,
  isOpen,
  closeSheet,
}: {
  children: ReactNode
  isOpen: boolean
  closeSheet: () => void
}) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  const handleDragEnd = (_event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    if (info.offset.y > CLOSE_THRESHOLD) closeSheet()
  }

  if (!mounted) return null

  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 z-50">
          <motion.div
            className="absolute inset-0 bg-black"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            onClick={closeSheet}
          />
          <motion.div
            initial={{ y: '100%' }}
            animate={{ y: 0 }}
            exit={{ y: '100%' }}
            transition={{ type: 'tween', duration: 0.3 }}
            drag="y"
            dragConstraints={{ top: 0 }}
            dragElastic={0}
            onDragEnd={handleDragEnd}
            onClick={e => e.stopPropagation()}
            className="fixed bottom-0 left-0 max-h-[60dvh] w-full overflow-auto rounded-t-2xl bg-secondary text-primary shadow-lg"
          >
            <div>{children}</div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>,
    document.body
  )
}

export default BottomSheet

import { HYDRATE } from 'next-redux-wrapper'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from '../store/store'

interface InitialState {
  page: number
  hasReachedEnd: boolean
  _metadata: { updatedFields: (keyof InitialState)[] }
}

const initialState: InitialState = {
  page: 1,
  hasReachedEnd: false,
  _metadata: { updatedFields: [] },
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    incrementNotificationsPage(state) {
      state.page++
      state._metadata.updatedFields.push('page')
    },
    setHasReachedEnd(state, action: PayloadAction<boolean>) {
      state.hasReachedEnd = action.payload
      state._metadata.updatedFields.push('hasReachedEnd')
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      (action): action is PayloadAction<AppState> => action.type === HYDRATE,
      (state, action) => {
        const serverApp = action.payload.notifications

        const fieldsToCheck: (keyof InitialState)[] = ['page', 'hasReachedEnd']

        function updateField<K extends keyof InitialState>(
          state: InitialState,
          field: K,
          value: InitialState[K]
        ): void {
          state[field] = value
        }

        fieldsToCheck.forEach(field => {
          if (serverApp._metadata.updatedFields.includes(field) && serverApp[field] !== state[field]) {
            updateField(state, field, serverApp[field])
          }
        })
        state._metadata.updatedFields = []
      }
    )
  },
})

export const { incrementNotificationsPage, setHasReachedEnd } = notificationsSlice.actions
export default notificationsSlice.reducer

import { HYDRATE } from 'next-redux-wrapper'

import { AppState, RootState } from '@redux/store/store'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

interface MobileState {
  sidePanel: {
    isOpen: boolean
  }
  bottomSheet: {
    isOpen: boolean
  }
  hideHeader: boolean
  hideTabNav: boolean
  contrastBackgroundColor: {
    [urlId: string]: 'light' | 'dark'
  }
  loadedImages: {
    [urlId: string]: boolean
  }
  _metadata: { updatedFields: string[] }
}

const initialState: MobileState = {
  sidePanel: {
    isOpen: false,
  },
  bottomSheet: {
    isOpen: false,
  },
  hideHeader: false,
  hideTabNav: false,
  contrastBackgroundColor: {},
  loadedImages: {},
  _metadata: { updatedFields: [] },
}

const mobileSlice = createSlice({
  name: 'mobile',
  initialState,
  reducers: {
    setChromeState: (state, action: PayloadAction<{ hideHeader?: boolean; hideTabNav?: boolean }>) => {
      if (action.payload.hideHeader !== undefined) {
        state.hideHeader = action.payload.hideHeader
        state._metadata.updatedFields.push('hideHeader')
      }
      if (action.payload.hideTabNav !== undefined) {
        state.hideTabNav = action.payload.hideTabNav
        state._metadata.updatedFields.push('hideTabNav')
      }
    },
    openSidePanel: state => {
      state.sidePanel.isOpen = true
    },
    closeSidePanel: state => {
      state.sidePanel.isOpen = false
    },
    openBottomSheet: state => {
      state.bottomSheet.isOpen = true
    },
    closeBottomSheet: state => {
      state.bottomSheet.isOpen = false
    },
    setContrastBackgroundColor: (state, action: PayloadAction<{ urlId: string; color: 'light' | 'dark' }>) => {
      state.contrastBackgroundColor[action.payload.urlId] = action.payload.color
    },
    setImageLoaded: (state, action: PayloadAction<{ urlId: string }>) => {
      state.loadedImages[action.payload.urlId] = true
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      (action): action is PayloadAction<AppState> => action.type === HYDRATE,
      (state, action) => {
        const serverApp = action.payload.mobile

        const fieldsToCheck: (keyof MobileState)[] = ['hideHeader', 'hideTabNav']
        function updateField<K extends keyof MobileState>(state: MobileState, field: K, value: MobileState[K]): void {
          state[field] = value
        }
        fieldsToCheck.forEach(field => {
          if (
            serverApp._metadata.updatedFields.includes(field) &&
            serverApp[field] !== undefined &&
            serverApp[field] !== state[field]
          ) {
            updateField(state, field, serverApp[field])
          }
        })
        state._metadata.updatedFields = []
      }
    )
  },
})

export const selectChromeState = createSelector(
  (state: RootState) => state.mobile.hideHeader,
  (state: RootState) => state.mobile.hideTabNav,
  (hideHeader, hideTabNav): { hideHeader: boolean; hideTabNav: boolean } => ({
    hideHeader,
    hideTabNav,
  })
)

export const selectContrastBackgroundColor = (state: RootState, urlId: string): 'light' | 'dark' | undefined => {
  return state.mobile.contrastBackgroundColor[urlId]
}

export const selectImageLoaded = (state: RootState, urlId: string): boolean => {
  return !!state.mobile.loadedImages[urlId]
}

export const {
  setChromeState,
  openSidePanel,
  closeSidePanel,
  openBottomSheet,
  closeBottomSheet,
  setContrastBackgroundColor,
  setImageLoaded,
} = mobileSlice.actions

export default mobileSlice.reducer

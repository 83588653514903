import { HYDRATE } from 'next-redux-wrapper'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from '../store/store'

interface InitialState {
  isMuted: boolean
  isAutoMuted: boolean
  hasEverUnmuted: boolean
  hasInteracted: boolean
  _metadata: { updatedFields: (keyof InitialState)[] }
}

const initialState: InitialState = {
  isMuted: true,
  isAutoMuted: false,
  hasEverUnmuted: false,
  hasInteracted: false,
  _metadata: { updatedFields: [] },
}

const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    setIsMuted(state, action: PayloadAction<boolean>) {
      state.isMuted = action.payload
      if (state.isAutoMuted) {
        state.isAutoMuted = false
        state._metadata.updatedFields.push('isAutoMuted')
      }
      state._metadata.updatedFields.push('isMuted')
    },
    setIsAutoMuted(state, action: PayloadAction<boolean>) {
      state.isAutoMuted = action.payload
      state._metadata.updatedFields.push('isAutoMuted')
    },
    setHasEverUnmuted(state, action: PayloadAction<boolean>) {
      state.hasEverUnmuted = action.payload
      state._metadata.updatedFields.push('hasEverUnmuted')
    },
    setHasInteracted(state, action: PayloadAction<boolean>) {
      state.hasInteracted = action.payload
      state._metadata.updatedFields.push('hasInteracted')
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      (action): action is PayloadAction<AppState> => action.type === HYDRATE,
      (state, action) => {
        const serverApp = action.payload.video

        const fieldsToCheck: (keyof InitialState)[] = ['isMuted', 'hasEverUnmuted', 'isAutoMuted']

        function updateField<K extends keyof InitialState>(
          state: InitialState,
          field: K,
          value: InitialState[K]
        ): void {
          state[field] = value
        }

        fieldsToCheck.forEach(field => {
          if (serverApp._metadata.updatedFields.includes(field) && serverApp[field] !== state[field]) {
            updateField(state, field, serverApp[field])
          }
        })
        state._metadata.updatedFields = []
      }
    )
  },
})

export const { setIsMuted, setIsAutoMuted, setHasEverUnmuted, setHasInteracted } = videoSlice.actions
export default videoSlice.reducer

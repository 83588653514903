import './MixAxios'

import { AxiosHeaders, AxiosRequestConfig } from 'axios'

import runtimeConfig from '@lib/RuntimeConfig'

const MixWebConfig = <Request>(requestConfig: AxiosRequestConfig<Request>): AxiosRequestConfig<Request> => ({
  baseURL: `${runtimeConfig().publicRuntimeConfig.baseUrl}`,
  headers: new AxiosHeaders({
    'Content-Type': 'application/json',
  }),
  withCredentials: true,
  ...requestConfig,
})

const MixWeb = {
  experiments: {
    joinTestGroup: (params: { testName: string; userId: number | undefined }) =>
      MixWebConfig({
        method: 'POST',
        url: '/api/experiments',
        params,
      }),
  },
  providers: {
    tiktok: {
      getVideoUrl: (params: { id: string }) => MixWebConfig({ method: 'GET', url: '/api/videos/tiktok', params }),
    },
  },
}
export default MixWeb

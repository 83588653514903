const SwipeCopy = {
  heading: 'Swipe, get inspired',
  subheading: 'Mix scours the internet then shows you the best stuff, without FOMO, politics, or news.',
}

const SwipeElement = () => (
  <>
    <h1 className="mb-8 text-6xl font-bold tracking-tight md:text-7xl lg:text-8xl 2xl:mb-12 2xl:text-[120px]">
      Swipe, get inspired
    </h1>
    <h2 className="mb-10 text-lg leading-tight opacity-50 lg:text-xl 2xl:text-2xl">
      Mix scours the internet then shows you the best stuff, without FOMO, politics, or news.
    </h2>
  </>
)

const Swipe = { element: SwipeElement, copy: SwipeCopy }

export default Swipe

import { IntoNode } from './IntoNode'
import { IntoUrl } from './IntoUrl'
import { IntoUser } from './IntoUser'
import { LoginPrompt } from './LoginPrompt'
import { WebInterstitial } from './WebInterstitial'

export type APIResourceType = 'user' | 'url' | 'node' | 'login' | 'web_interstitial'

export interface APIResource {
  type: APIResourceType
}

export type FollowableAPIResource = IntoUser | IntoNode

export type FeedItem = IntoUser | IntoUrl | IntoNode | LoginPrompt | WebInterstitial

export const keyForResource = (resource: FeedItem | undefined): string | number | undefined => {
  if (!resource) return
  switch (resource.type) {
    case 'user':
      return resource.user_id
    case 'url':
      return resource.url_id
    case 'node':
      return resource.slug
    case 'login':
      return resource.id
    case 'web_interstitial':
      return resource.id
  }
}

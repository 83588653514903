import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react'
import { useImmer } from 'use-immer'

import { IExpliotResponse } from '../exploits/useRegisterPassive'
import { ServiceWorkerMessageTag } from '../utils/tags'

const MessageServiceContext = createContext<MessageServiceContextProps | undefined>(undefined)

export const useMessageService = () => {
  const context = useContext(MessageServiceContext)
  if (!context) {
    throw new Error('useMessageService must be used within a MessageServiceProvider')
  }
  return context
}

type MessageHandler = (data: IExpliotResponse) => void

interface MessageServiceContextProps {
  addHandler: (tag: ServiceWorkerMessageTag, handler: MessageHandler) => void
  removeHandler: (tag: ServiceWorkerMessageTag, handler: MessageHandler) => void
}

interface IMessageServiceProps {
  children: React.ReactNode
}

export const MessageServiceProvider: React.FC<IMessageServiceProps> = ({ children }) => {
  const [handlers, setHandlers] = useImmer<Record<string, MessageHandler[] | undefined>>({})
  const handleMessage = useCallback(
    (event: MessageEvent) => {
      const message = event.data

      if (message.tag && message.tag in handlers) {
        handlers[message.tag]?.forEach(handler => handler(message))
      }
    },
    [handlers]
  )

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', handleMessage)
      return () => {
        navigator.serviceWorker.removeEventListener('message', handleMessage)
      }
    }
  }, [handleMessage])

  const addHandler = useCallback(
    (tag: string, handler: MessageHandler) => {
      setHandlers(draft => {
        if (!draft[tag]) {
          draft[tag] = []
        }
        draft[tag]?.push(handler)
      })
    },
    [setHandlers]
  )

  const removeHandler = useCallback(
    (tag: string, handler: MessageHandler) => {
      setHandlers(draft => {
        const handlersArray = draft[tag]
        if (handlersArray) {
          const index = handlersArray.indexOf(handler)
          if (index > -1) {
            handlersArray.splice(index, 1)
          }
          if (handlersArray?.length === 0) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete draft[tag]
          }
        }
      })
    },
    [setHandlers]
  )

  const value = useMemo(() => ({ addHandler, removeHandler }), [addHandler, removeHandler])

  return <MessageServiceContext.Provider value={value}>{children}</MessageServiceContext.Provider>
}

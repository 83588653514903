import React, { useMemo } from 'react'

import { ReactJsonViewProps } from '@microlink/react-json-view'

const DynamicReactJsonView = (props: ReactJsonViewProps) => {
  // eslint-disable-next-line @typescript-eslint/no-require-imports
  const Component = useMemo(() => require('@microlink/react-json-view').default, [])
  return <Component {...props} />
}
export default DynamicReactJsonView

interface IconThumbProps {
  direction: 'up' | 'down'
  isSelected: boolean
  size?: number
}

const DEFAULT_SIZE = 24

export const IconThumb = ({ direction, isSelected, size = DEFAULT_SIZE }: IconThumbProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      className={`${direction === 'down' ? 'rotate-180' : ''}`}
    >
      {/* Stroke: Used for the normal state */}
      <path
        className={['fill-none stroke-primary stroke-2', isSelected ? 'hidden' : ''].join(' ')}
        d="M6.99997 22H4.32997C3.76401 22.01 3.21407 21.8119 2.78456 21.4432C2.35504 21.0745 2.07584 20.5609 1.99997 20V13C2.07584 12.4391 2.35504 11.9255 2.78456 11.5568C3.21407 11.1881 3.76401 10.99 4.32997 11H6.99997M14 9V5C14 4.20435 13.6839 3.44129 13.1213 2.87868C12.5587 2.31607 11.7956 2 11 2L6.99997 11L6.99997 22L18.28 22C18.7623 22.0055 19.2303 21.8364 19.5979 21.524C19.9654 21.2116 20.2076 20.7769 20.28 20.3L21.66 11.3C21.7035 11.0134 21.6841 10.7207 21.6033 10.4423C21.5225 10.1638 21.3821 9.9063 21.1918 9.68752C21.0016 9.46874 20.766 9.29394 20.5015 9.17522C20.237 9.05651 19.9499 8.99672 19.66 9L14 9Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {/* Filled state: Hand */}
      <path
        className={['fill-primary', isSelected ? '' : 'hidden'].join(' ')}
        d="m8 11.212-.914-.406a1 1 0 0 0-.086.406h1zm3.608-8.117.304-.953a1 1 0 0 0-1.218.546l.914.407zm.806.49-.707.708.707-.707zM19.66 10v1h.006l-.006-1zm.011 0 .006 1h.006l-.012-1zm.421.088.41-.913-.41.913zm.345.256-.754.656.754-.656zm.206.377.96-.279-.96.28zm.028.429.989.151V11.3l-.989-.15zm-1.38 9L18.303 20V20l.988.15zm-.34.612L18.302 20l.647.762zm-.66.238.012-1h-.012v1zM8 21H7a1 1 0 0 0 1 1v-1zm-3-9h1a1 1 0 0 0-1-1v1zm0 9v1a1 1 0 0 0 1-1H5zm-2.707-8.707-.707-.707.707.707zm0 8.414L3 20l-.707.707zm6.62-9.089 3.609-8.117-1.828-.813-3.608 8.118 1.828.812zm2.39-7.57a1 1 0 0 1 .404.245l1.414-1.414a3 3 0 0 0-1.21-.737l-.607 1.905zm.404.245A1 1 0 0 1 12 5h2a3 3 0 0 0-.879-2.121l-1.414 1.414zM12 5v4h2V5h-2zm0 4a2 2 0 0 0 2 2V9h-2zm2 2h5.66V9H14v2zm5.666 0h.011l-.011-2h-.012l.012 2zm.017 0 .819-1.825A2 2 0 0 0 19.66 9l.023 2zm0 0 1.509-1.312a2 2 0 0 0-.69-.513L19.682 11zm0 0 1.92-.558a2 2 0 0 0-.411-.754L19.682 11zm0 0 1.977.3a2 2 0 0 0-.057-.858l-1.92.558zm-1.38 9 1.295 1.524a2 2 0 0 0 .682-1.224l-1.977-.3zm0 0-.023 2a2 2 0 0 0 1.318-.476L18.303 20zM9 21v-9.788H7V21h2zm-5-9v9h2v-9H4zm-1 1h2v-2H3v2zm0 0v-2a2 2 0 0 0-1.414.586L3 13zm0 0-1.414-1.414A2 2 0 0 0 1 13h2zm0 7v-7H1v7h2zm0 0H1a2 2 0 0 0 .586 1.414L3 20zm0 0-1.414 1.414A2 2 0 0 0 3 22v-2zm2 0H3v2h2v-2zm14.683-9.002-1.38 9 1.977.304 1.38-9-1.977-.304zM18.29 20H8v2h10.291v-2z"
      />
      {/* Filled state: Cuff */}
      <path
        className={['fill-primary', isSelected ? '' : 'hidden'].join(' ')}
        d="m8 11.212 3.608-8.117A2 2 0 0 1 13 5v4a1 1 0 0 0 1 1h5.671a1 1 0 0 1 1 1.15l-1.38 9a1 1 0 0 1-1 .85H8v-9.788zM5 21v-9H3a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h2z"
        fillRule="evenodd"
      />
    </svg>
  )
}

import React from 'react'

export const ContestHeroVideo = () => (
  <video
    muted
    autoPlay
    loop
    playsInline
    className="size-full object-cover"
    poster="https://assets.mix.com/static/contests/2024/breakdancing/background_poster.jpg"
  >
    <source src="https://assets.mix.com/static/contests/2024/breakdancing/background.webm" type="video/webm" />
    <source src="https://assets.mix.com/static/contests/2024/breakdancing/background.mp4" type="video/mp4" />
  </video>
)

// noinspection JSUnusedGlobalSymbols

export const SECOND_TO_MS = 1000

export const MINUTE_TO_SECONDS = 60
export const MINUTE_TO_MS = MINUTE_TO_SECONDS * SECOND_TO_MS

export const HOUR_TO_MINUTES = 60
export const HOUR_TO_SECONDS = HOUR_TO_MINUTES * MINUTE_TO_SECONDS

export const DAY_TO_HOURS = 24
export const DAY_TO_SECONDS = DAY_TO_HOURS * HOUR_TO_SECONDS
export const DAY_TO_MS = DAY_TO_SECONDS * SECOND_TO_MS

export const WEEK_TO_DAYS = 7
export const WEEK_TO_SECONDS = WEEK_TO_DAYS * DAY_TO_SECONDS

export const MONTH_TO_WEEKS = 5

export const YEAR_TO_MONTHS = 12
export const YEAR_TO_DAYS = 365
export const YEAR_TO_SECONDS = YEAR_TO_DAYS * DAY_TO_SECONDS
export const YEAR_TO_MS = YEAR_TO_SECONDS * SECOND_TO_MS

import { HYDRATE } from 'next-redux-wrapper'

import { IntoUrl } from '@models/IntoUrl'
import { IntoUser } from '@models/IntoUser'
import { TimeCapsuleResponse } from '@models/TimeCapsuleResponse'
import { AppState, RootState } from '@redux/store/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type TimeCapsule = TimeCapsuleResponse & {
  screen_9_top_n_affinity_within_most_viewed_topic_urls: IntoUrl[]
  user: IntoUser
}

export type ShareMode = 'navigator' | 'mix_scheme' | 'none'

export interface StoryMetadata {
  hasCTA: boolean
  name: string
  index: number
}

export interface TimeCapsuleState {
  initialStoryIdx: number
  activeStoryIdx: number
  totalStories: number
  storyMetadata: StoryMetadata[]
  isPaused: boolean
  isStoriesOpen: boolean
  isScreenshot: boolean
  addTopPadding: boolean
  shareMode: ShareMode
  screenshots: string[]
  currentCapsule: TimeCapsule | null
  encryptedUserId: string | null
  _metadata: { updatedFields: string[] }
}

const initialState: TimeCapsuleState = {
  initialStoryIdx: 0,
  activeStoryIdx: 0,
  totalStories: 0,
  isStoriesOpen: false,
  storyMetadata: [],
  isPaused: false,
  isScreenshot: false,
  addTopPadding: false,
  shareMode: 'navigator',
  screenshots: [],
  currentCapsule: null,
  encryptedUserId: null,
  _metadata: { updatedFields: [] },
}

const timeCapsuleSlice = createSlice({
  name: 'timeCapsule',
  initialState,
  reducers: {
    setInitialStoryIdx: (state, action: PayloadAction<number>) => {
      state.initialStoryIdx = action.payload
      state._metadata.updatedFields.push('initialStoryIdx')
    },
    setActiveStoryIdx: (state, action: PayloadAction<number>) => {
      state.activeStoryIdx = action.payload
      state._metadata.updatedFields.push('activeStoryIdx')
    },
    setTotalStories: (state, action: PayloadAction<number>) => {
      state.totalStories = action.payload
      state._metadata.updatedFields.push('totalStories')
    },
    setIsStoriesOpen: (state, action: PayloadAction<boolean>) => {
      state.isStoriesOpen = action.payload
      state._metadata.updatedFields.push('isStoriesOpen')
    },
    setIsScreenshot: (state, action: PayloadAction<boolean>) => {
      state.isScreenshot = action.payload
      state._metadata.updatedFields.push('isScreenshot')
    },
    setAddTopPadding: (state, action: PayloadAction<boolean>) => {
      state.addTopPadding = action.payload
      state._metadata.updatedFields.push('addTopPadding')
    },
    setShareMode: (state, action: PayloadAction<ShareMode>) => {
      state.shareMode = action.payload
      state._metadata.updatedFields.push('shareMode')
    },
    setScreenshot: (state, action: PayloadAction<{ index: number; screenshot: string }>) => {
      state.screenshots[action.payload.index] = action.payload.screenshot
      state._metadata.updatedFields.push('screenshots')
    },
    setCurrentCapsule: (state, action: PayloadAction<TimeCapsule>) => {
      state.currentCapsule = action.payload
      state._metadata.updatedFields.push('currentCapsule')
    },
    setEncryptedUserId: (state, action: PayloadAction<string>) => {
      state.encryptedUserId = action.payload
      state._metadata.updatedFields.push('encryptedUserId')
    },
    setStoryMetadata: (state, action: PayloadAction<StoryMetadata>) => {
      state.storyMetadata[action.payload.index] = action.payload
      state._metadata.updatedFields.push('storyMetadata')
    },
    setIsPaused: (state, action: PayloadAction<boolean>) => {
      state.isPaused = action.payload
      state._metadata.updatedFields.push('isPaused')
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      (action): action is PayloadAction<AppState> => action.type === HYDRATE,
      (state, action) => {
        const serverApp = action.payload.timeCapsule

        const fieldsToCheck: (keyof TimeCapsuleState)[] = [
          'initialStoryIdx',
          'activeStoryIdx',
          'totalStories',
          'addTopPadding',
          'shareMode',
          'currentCapsule',
          'screenshots',
          'isStoriesOpen',
          'isScreenshot',
          'encryptedUserId',
          'storyMetadata',
          'isPaused',
        ]

        function updateField<K extends keyof TimeCapsuleState>(
          state: TimeCapsuleState,
          field: K,
          value: TimeCapsuleState[K]
        ): void {
          state[field] = value
        }

        fieldsToCheck.forEach(field => {
          if (
            serverApp._metadata.updatedFields.includes(field) &&
            serverApp[field] !== undefined &&
            serverApp[field] !== state[field]
          ) {
            updateField(state, field, serverApp[field])
          }
        })
        state._metadata.updatedFields = []
      }
    )
  },
})

export const selectInitialStoryIdx = (state: RootState): number => state.timeCapsule.initialStoryIdx
export const selectActiveStoryIdx = (state: RootState): number => state.timeCapsule.activeStoryIdx
export const selectAddTopPadding = (state: RootState): boolean => state.timeCapsule.addTopPadding
export const selectCurrentCapsule = (state: RootState): TimeCapsule | null => state.timeCapsule.currentCapsule
export const selectIsStoriesOpen = (state: RootState): boolean => state.timeCapsule.isStoriesOpen
export const selectIsScreenshot = (state: RootState): boolean => state.timeCapsule.isScreenshot
export const selectScreenshots = (state: RootState): string[] => state.timeCapsule.screenshots
export const selectTotalStories = (state: RootState): number => state.timeCapsule.totalStories
export const selectEncryptedUserId = (state: RootState): string | null => state.timeCapsule.encryptedUserId
export const selectStoryMetadata = (state: RootState): StoryMetadata[] => state.timeCapsule.storyMetadata
export const selectIsPaused = (state: RootState): boolean => state.timeCapsule.isPaused
export const selectShareMode = (state: RootState): ShareMode => state.timeCapsule.shareMode
export const selectCapsuleField =
  <K extends keyof TimeCapsule>(field: K) =>
  (state: RootState): TimeCapsule[K] | undefined =>
    state.timeCapsule.currentCapsule?.[field]

export const {
  setInitialStoryIdx,
  setActiveStoryIdx,
  setAddTopPadding,
  setCurrentCapsule,
  setIsStoriesOpen,
  setIsScreenshot,
  setShareMode,
  setScreenshot,
  setTotalStories,
  setEncryptedUserId,
  setStoryMetadata,
  setIsPaused,
} = timeCapsuleSlice.actions

export default timeCapsuleSlice.reducer

import { IncomingMessage } from 'node:http'

const Utils = {
  ipFromReq: (req: IncomingMessage): string | undefined => {
    const forwardedForHeader = (() => {
      if (req.headers['x-forwarded-for'] instanceof Array) {
        return req.headers['x-forwarded-for'][0]
      }
      return req.headers['x-forwarded-for']?.split(',')?.[0]
    })()
    return forwardedForHeader ?? req.socket.remoteAddress
  },
}

export default Utils
